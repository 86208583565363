<template>
  <div class="App">
    <div style="padding-top: 100px">
      <div>
        <div class="inner-block">
          <div v-if="!isMemberConfirm" class="vue-tempalte">
            <h3>ลงทะเบียน</h3>
            <div class="form-group">
              <label>เลขที่สมาชิก</label>
              <input
                @keypress="onlyForMember"
                v-model="form.memberid"
                type="text"
                class="form-control form-control-lg"
              />
            </div>
            <div class="form-group form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="inlineCheckbox1"
                @click="form.isPersonalUser = !form.isPersonalUser"
                :checked="form.isPersonalUser"
              />
              <label class="form-check-label" for="inlineCheckbox1"
                >บุคคลธรรมดา</label
              >
            </div>
            <div class="form-group form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="inlineCheckbox2"
                @change="form.isPersonalUser = !form.isPersonalUser"
                :checked="!form.isPersonalUser"
              />
              <label class="form-check-label" for="inlineCheckbox2"
                >นิติบุคคล</label
              >
            </div>
            <div class="form-group">
              <label v-if="form.isPersonalUser">เลขที่บัตรประชาชน</label>
              <label v-else>เลขที่บัตรประชาชน (ผู้แทนสมาชิก)</label>
              <input
                @keypress="onlyForNumber"
                v-model="form.idcard"
                type="text"
                class="form-control form-control-lg"
              />
            </div>

            <!-- <div class="form-group form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="changeAddressId"
                @click="form.isAddresschange = !form.isAddresschange"
                :checked="form.isAddresschange"
              />
              <label class="form-check-label" for="changeAddressId"
                >เปลี่ยนที่อยู่</label
              >
            </div> -->
          </div>
          <div v-else>
            <div class="form-group">
              <label>รหัสผ่าน *</label>
              <input
                @keypress="validateSamePassword"
                v-model="updatemember.passwd1"
                type="text"
                class="form-control form-control-lg"
              />
            </div>

            <div class="form-group">
              <label>ยืนยันรหัสผ่าน *</label>
              <input
                @keypress="validateSamePassword"
                v-model="updatemember.passwd2"
                type="text"
                class="form-control form-control-lg"
              />
            </div>
            <div v-if="member != null" class="form-group">
              <div v-if="member.mobile != ''">
                <label>เบอร์มือถือ (ถ้าต้องการเปลียน)</label>
                <label>เบอร์เดิม {{ member.mobile }}</label>
                <input
                  @keypress="onlyForNumber"
                  type="text"
                  v-model="updatemember.mobile"
                  class="form-control form-control-lg"
                  :disabled="form.isRegisterDone"
                />
              </div>
              <div v-else>
                <label>เบอร์มือถือ</label>
                <label>เบอร์เดิม {{ member.mobile }}</label>
                <input
                  @keypress="onlyForNumber"
                  type="text"
                  v-model="updatemember.mobile"
                  class="form-control form-control-lg"
                  :disabled="form.isRegisterDone"
                />
              </div>
            </div>
            <div class="form-group">
              <label>อีเมล์ (ถ้ามี)</label>
              <input
                v-model="updatemember.email"
                type="email"
                class="form-control form-control-lg"
              />
            </div>

            <div class="form-group">
              <label>ที่อยู่ (ถ้าต้องการเปลี่ยน)</label>
              <input
                type="text"
                v-model="updatemember.address"
                class="form-control form-control-lg"
              />
            </div>
          </div>
          <div v-if="!isMemberConfirm" class="form-group">
            <button
              @click="getPhotofairMemberId"
              class="form-control form-control-lg btn btn-dark btn-lg btn-block"
            >
              ลงทะเบียน
            </button>
          </div>
          <div v-else>
            <button
              @click="updateLoginPasswd"
              class="form-control form-control-lg btn btn-dark btn-lg btn-block"
            >
              เก็บข้อมูล
            </button>
          </div>
                  <button
              @click="$router.push('SignIn')"
              type="button"
              class="btn btn-warning btn-lg btn-block"
            >
              เคยลงทะเบียนแล้ว ล็อกอิน
            </button>
          <!--p class="forgot-password text-right">
            Already registered
            <router-link :to="{ name: 'SignIn' }">sign in?</router-link>
          </p-->
          <div>
            <p class="forgot-password text-right">{{ belowmessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestoreref } from "@/config/firebaseConfig";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      belowmessage: "",
      updatemember: {
        email: "",
        passwd1: "",
        passwd2: "",
        mobile: "",
        address: "",
      },
      idno: 0,
      isSamePassword: false,
      member: null,
      tmpmobile: [],
      isMemberConfirm: false,
      form: {
        memberid: "",
        idcard: "",
        mobile: "",
        address: "",
        otp: "",
        isRegisterDone: false,
        isPersonalUser: true,
        isAddresschange: false,
        token: "JINNSOLUTION_PBA2.0",
      },
      error: null,
    };
  },
  created() {
    this.getExistedMember();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    changeAddress() {
      //console.log("click change address");
      this.form.isAddresschange = !this.form.isAddresschange;
    },
    getExistedMember() {
      var storemember = window.localStorage.getItem("member");
      this.form =
        storemember != null
          ? JSON.parse(storemember)
          : {
              memberid: "",
              token: "JINNSOLUTION_PBA2.0",
              idcard: "",
              mobile: "",
              otp: "",
              isRegisterDone: false,
              isPersonalUser: true,
            };
      this.form.isRegisterDone = false;
      this.form.otp = "";
    },
    onlyForMember($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
      this.form.isRegisterDone = false;
    },
    onlyForNumber($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    validateMobile(mobile) {
      if (mobile.length != 10) {
        return false;
      }
      for (let i = 0; i < mobile.length; i++) {
        if (mobile.charCodeAt(i) < 48 || mobile.charCodeAt(i) > 57) {
          return false;
        } else {
          if (i == 0 && mobile.charCodeAt(i) != 48) {
            return false;
          }
          if (
            i == 1 &&
            !(
              mobile.charCodeAt(i) == 56 ||
              mobile.charCodeAt(i) == 57 ||
              mobile.charCodeAt(i) == 54
            )
          ) {
            return false;
          }
        }
      }
      return true;
    },
    validateSamePassword() {
      if (this.passwd1 != this.passwd2) {
        this.isSamePassword = false;
        return "(พาสเวอด์ยังไม่เหมือนกัน)";
      } else {
        this.isSamePassword = true;
        return "";
      }
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    getMemberPbaThai() {
      let alreadyregister = "ลงทะเบียนตั้งรหัสไปแล้ว โปรด ล็อกอิน";
      let request = {
        request: "member_register",
        token: this.form.token,
        condition: this.form.memberid,
      };
      //console.log(request);
      axios
        .post("https://pba-thai.com/api/1.0/", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.result == 'success' && response.data.data[0] != null ) this.$alert(alreadyregister);
          else this.isMemberConfirm = true;
          //console.log(response.data);
        });
    },
    updateMemberPbaThai() {
      let failmember = "ยังลงทะเบียนไม่ผ่าน";
      if (this.member != null) {
        if (this.updatemember.mobile != "") {
          this.member.mobile = "* " + this.updatemember.mobile;
        }
        if (this.updatemember.address != "") {
          this.member.address = "* " + this.updatemember.address;
        }
        let request = {
          request: "member_registerinsert",
          token: this.form.token,
          request_member: this.member,
        };
        //console.log(request);
        axios
          .post("https://pba-thai.com/api/1.0/", request, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
           //console.log(response);
           if(response.data.result == 'success') {
             this.$alert("ลงทะเบียนสำเร็จ"); 
             this.$router.go(0) ; 
           }
          });
      } else {
        this.$alert(failmember);
      }
    },
    updateLoginPasswd() {
      let invalidpassword =
        " โปรดใส่ password ให้ตรงกัน และ email ให้ถูกรูปแบบ";

      let isValidemail = true;
      if (this.updatemember.email != "") {
        if (!this.validEmail(this.updatemember.email)) isValidemail = false;
      }
      if (
        this.updatemember.passwd1 == this.updatemember.passwd2 &&
        this.updatemember.passwd2 != "" &&
        isValidemail
      ) {
        let request = {
          request_function: "update_memberpasswd",
          request_member_id: this.form.memberid,
          request_idcard: this.form.idcard,
          request_idno: this.idno,
          request_email: this.updatemember.email,
          request_passwd: this.updatemember.passwd1,
          token: this.form.token,
        };
        //console.log(request);
        axios
          .post("https://photofairbangkok.com/pba-member/api/3.0/", request, {
            headers: {
              "Content-Type": "multipart/form-data",
              //"Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.result == "fail" || response.data == null) {
              window.localStorage.removeItem("PBAMEMBER");
              this.$alert(" fail to update")
            } else {

              let loginMember = {
                member_id: this.form.memberid,
                member_passwd: this.updatemember.passwd1,
                type: "member",
              };
              window.localStorage.setItem(
                "PBAMEMBER",
                JSON.stringify(loginMember)
              );
              //this.$router.go(0);
               this.updateMemberPbaThai();
              this.$router.push({ path: "/" });
            }
          });
      } else {
        this.$alert(invalidpassword);
      }
    },
    compareOTP() {
      let incorrectotp = " OTP ไม่ถูกต้อง หรือเกินเวลา 15 นาที";
      let emptyotp = "ท่านยังไมไ่ดใส่เลข OTP";
      if (this.form.otp != "") {
        let request = {
          request_function: "otp_compare",
          request_member_id: this.form.memberid,
          request_otp: this.form.otp,
          token: this.form.token,
        };
        axios
          .post("https://photofairbangkok.com/pba-member/api/2.0/", request, {
            headers: {
              "Content-Type": "multipart/form-data",
              //"Content-Type": "application/json",
            },
          })
          .then((response) => {
            //console.log(response.data);
            if (response.data.length == 0) {
              this.isRegisterDone = false;
              this.form.otp = "";
              this.$alert(incorrectotp);
            } else {
              // correct otp

              window.localStorage.setItem("member", JSON.stringify(this.form));
              this.isMemberConfirm = true;
              //this.setUser(this.member);
            }
          });
      } else {
        this.$alert(emptyotp);
      }
    },
    getPhotofairMemberId() {
      var incorrectmember =
        "เลขเมมเบอร์ไอดีไม่ตรง หรือ เลขบัตรปชชไม่ตรง หรือไม่มีเลขบัตรปชช ในระบบ โปรดติดต่อเจ้าหน้าที่สมาคม 02-8037570-1 เพื่อ Update";

      let request = {
        request_function: "member_getidcard",
        request_search_condition: "member",
        request_member_id: this.form.memberid,
        request_member_idcard: this.form.idcard,
        token: this.form.token,
      };
     //console.log(request);
      axios
        .post("https://photofairbangkok.com/pba-member/api/3.0/", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
         //console.log(response.data[0]);
          if (response.data[0] != null) {
            this.member = response.data[0];
            if (this.member != null) {
              //console.log(this.member) ;

              if (this.member.idcard1 == this.form.idcard) {
                this.idno = 1;
              }
              if (this.member.idcard2 == this.form.idcard) {
                this.idno = 2;
              }
              if (this.member.idcard3 == this.form.idcard) {
                this.idno = 3;
              }
              this.getMemberPbaThai();
              //this.isMemberConfirm = true;
            }
          } else {
            this.$alert(incorrectmember);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getMemberId() {
      this.form.otp = "";
      //do something... member_get
      var invalidmobile =
        "โปรดกรอกหมายเลขโทรศัพท์ 10 หลัก ด้วยรูปแบบดังนี้ 08XXXXXXXX หรือ 09XXXXXXXX ไม่ต้องใส่เครื่องหมายขีด (-) วงเล็บหรือเว้นวรรค";
      var nomobilerecord =
        "ไม่มีเลขโทรศัพท์มือถือบันทึกให้ฐานข้อมูลสมาคม โปรดติดต่อเจ้าหน้าที่สมาคม";
      var incorrectmobile =
        "เบอร์โทรที่ท่านกรอกไม่ตรงกับข้อมูลที่อยู่ในฐานข้อมูลสมาคม โปรดติดต่อเจ้าหน้าที่";
      //var alreadyregisask = "ท่านมี email ได้เคยลงทะเบียนไว้แล้ว ท่านสามารถกด Cancel แล้ว กด link ด้านล้างเพื่อส่ง Password ไปที่ email"
      var confirmsentotp =
        "กด OK ยืนยันการส่ง otp ที่เบอร์ " + this.form.mobile;
      var incorrectmember = "เลขเมมเบอร์ไอดีไม่ตรง";
      var overotpfailmsg = " ท่านส่ง OTP มากกว่า 3 ครั้งในวันนี้แล้ว";
      //var alreadyregister =
      //  "กดส่ง password ไปที่ email ที่ท่านได้เคยลงทะเบียนได้";

      let request = {
        request_function: "member_getotp",
        request_search_condition: "member",
        request_member_id: this.form.memberid,
        token: this.form.token,
      };
      axios
        .post("https://photofairbangkok.com/pba-member/api/2.0/", request, {
          headers: {
            "Content-Type": "multipart/form-data",
            //"Content-Type": "application/json",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.result == "overotp") {
            this.$alert(overotpfailmsg);
          } else if (response.data.result == "success") {
            this.member = response.data.data;
            this.updatemember.email = this.member.email;

            // if (this.member.email !="") {
            //   this.belowmessage =  alreadyregister ;
            //   confirmsentotp = alreadyregisask + confirmsentotp ;
            // }

            if (this.member != null) {
              //this.member.mobile = "0819-46 0994";
              //
              this.member.mobile = this.member.mobile.replaceAll("-", "");
              this.member.mobile = this.member.mobile.replaceAll(" ", "");
              this.tmpmobile = this.member.mobile.split(",");
              if (this.tmpmobile.length > 0) {
                for (let i = 0; i < this.tmpmobile.length; i++) {
                  if (this.validateMobile(this.form.mobile)) {
                    if (this.tmpmobile[i] == this.form.mobile) {
                      this.form.isRegisterDone = false;
                      window.localStorage.setItem(
                        "member",
                        JSON.stringify(this.form)
                      );
                      this.$confirm(confirmsentotp).then(() => {
                        this.otpToMemberId();
                        this.form.isRegisterDone = true;
                        window.localStorage.setItem(
                          "member",
                          JSON.stringify(this.form)
                        );
                      });
                      break;
                    } else {
                      if (i == this.tmpmobile.length - 1)
                        this.$alert(incorrectmobile);
                    }
                  } else {
                    this.$alert(invalidmobile);
                  }
                }
              } else {
                if (this.member.mobile != "") {
                  if (this.validateMobile(this.form.mobile)) {
                    if (this.member.mobile == this.form.mobile) {
                      this.form.isRegisterDone = false;

                      this.$confirm(confirmsentotp).then(() => {
                        this.otpToMemberId();
                        this.form.isRegisterDone = true;
                        window.localStorage.setItem(
                          "member",
                          JSON.stringify(this.form)
                        );
                      });
                    } else {
                      this.$alert(invalidmobile);
                    }
                  } else {
                    this.$alert(invalidmobile);
                  }
                } else {
                  this.$alert(nomobilerecord);
                }
              }
            }
          } else {
            this.$alert(incorrectmember);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    otpToMemberId() {
      let smsfail = " ส่ง SMS ระบบส่งผิดพลาด  ";
      let request = {
        request_function: "otp_member",
        request_member_id: this.form.memberid,
        request_member_mobile: this.form.mobile,
        token: this.form.token,
      };
      axios
        .post("https://photofairbangkok.com/pba-member/api/2.0/", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.result == "fail") {
            //console.log(response.data);
            this.$alert(smsfail);
            this.isRegisterDone = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    updateFirestore() {
      //console.log("pba_user");
      firestoreref
        .collection("pba_user")
        .add(this.form)
        .then(function (docRef) {
         //console.log("Document written with ID: ", docRef.id);
          firestoreref.collection("pba_user").doc(docRef.id).update({
            id: docRef.id,
          });
          // this.$router.go(0);
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    },
  },
};
</script>
<style scoped>
.otp input[type="text"] {
  background-color: #ca8181;
}
.btn-block {
  margin-left: 0!important;
}
</style>