//import * as types from './mutation-types';

const mutations = {
  FETCH_USERS (state, users) {
    state.users = users;
  },

  ADD_USER (state, user) {
    state.users.push(user);
  },

  ADD_NEW_USER_ERROR (state, error) {
    state.addNewUserError = error;
  },

  EDIT_USER (state, editedUser) {
    state.users = state.users.map(user => {
      if (user.id === editedUser.id) {
        return {
          ...user,
          ...editedUser
        }
      }
      return user;
    });
  },

  DELETE_USER (state, id) {
    state.users = state.users.filter(user => user.id !== id);
  }
};

export default mutations;