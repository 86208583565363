import Vue from 'vue'
import VueRouter from 'vue-router'
import SignUp from '@/components/SignUp'
import SignIn from '@/components/SignIn'
import WebVision from '@/components/WebVision'
import Gallery from '@/components/Gallery'
import ConfirmOTP from '@/components/ConfirmOTP'
import AdminGallery from '@/components/AdminGallery'
import AdminOTP from '@/components/AdminOTP'
import Voting from '@/components/Voting'
import VoteLanding from '@/components/VoteLanding'
import VoteResult from '@/components/VoteResult'
import ChangePasswd from '@/components/ChangePasswd'
import Registration from '@/components/Registeration'
//import VotingResult from '@/components/VotingResult'
import AdminQuestion from '@/components/AdminQuestionLand'
import AdminQuestionEdit from '@/components/AdminQuestion'
import AdminQuestionNew from '@/components/AdminQuestionNew'
import AdminVoteGraph from '@/components/AdminVoteGraph'
import AdminVoteTable from '@/components/AdminVoteTable'
import AdminVoteTableCorrect from '@/components/AdminVoteTableCorrect'
import QuestionAnswer from '@/components/QuestionAnswer'
import AdminChoice from '@/components/AdminChoice'
import AdminRegister from '@/components/AdminRegister'
import RandomRegister from '@/components/AdminRandomList'
import Admin from '@/components/AdminSignIn'
import Home from '@/components/Home'
import CaptureTest from '@/components/CaptureTest'

Vue.use(VueRouter)
const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/otp', name: 'OTP', component: ConfirmOTP },
  { path: '/signup', name: 'SignUp', component: SignUp },
  { path: '/singin', name: 'SignIn', component: SignIn },
  { path: '/vision', name: 'WebVision', component: WebVision },
  { path: '/gallery', name: 'Gallery', component: Gallery },
  { path: '/admin/gallery', name: 'AdminGallery', component: AdminGallery },
  { path: '/admin/login', name: 'AdminLogin', component: Admin },
  { path: '/admin/question', name: 'AdminQuestion', component: AdminQuestion },
  { path: '/admin/question/:id', name: 'AdminChoice', component: AdminChoice },
  { path: '/admin/question/graph/:id', name: 'AdminGraph', component: AdminVoteGraph },
  { path: '/admin/questionnew', name: 'AdminQuestionNew', component: AdminQuestionNew },
  { path: '/admin/question/question/:id', name: 'AdminQuestionEdit', component: AdminQuestionEdit },
  { path: '/admin/question/table/:id', name: 'AdminVoteResultTable', component: AdminVoteTable },
  { path: '/admin/question/tablecorrect/:id', name: 'AdminVoteResultTableCorrect', component: AdminVoteTableCorrect },
  { path: '/admin', name: 'Admin', component: Admin },
  { path: '/admin/otp', name: 'AdminOTP', component: AdminOTP },
  { path: '/admin/registerlist', name: 'AdminRegister', component: AdminRegister },
  { path: '/admin/randomlist', name: 'RandomRegister', component: RandomRegister },
  { path: '/votelanding', name: 'VoteLaning', component: Voting },
  { path: '/register', name: 'Register', component: Registration },
  { path: '/ctest', name: 'Capture', component: CaptureTest },
  { path: '/voting', name: 'Voting', component: VoteLanding },
  { path: '/voteresult', name: 'VoteResult', component: VoteResult },
  { path: '/questionanswer', name: 'QuestionAnswer', component: QuestionAnswer },
  { path: '/changepasswd', name: 'ChangePasswd', component: ChangePasswd },
  { path: '*', name: 'UnKnown', component: SignIn },
]

const router = new VueRouter({
  mode: 'history',  // this may cause the store state data lost
  base: process.env.BASE_URL,
  //base: '/',
  // base: process.env.NODE_ENV === 'production'
  // ? '/member/'
  // : '/',
  routes
})

export function resetRouter() {
  const newRouter = router()
  router.matcher = newRouter.matcher // reset router
}

export default router