<template>
  <div style="margin-top: 80px">
    <div class="container mt-4">
      <table class="table table-bordered">
        <thead class="thead-light">
          <tr>
            <th width="1">#</th>

            <th width="40%">คำถาม</th>
            <th width="10%">รูป(ถ้ามี)</th>
            <th width="100%">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ index + 1 }}</td>

            <td>
              <span v-if="editIndex !== index">{{ item.choice }}</span>
              <span v-if="editIndex === index">
                <input
                  class="form-control form-control-sm"
                  v-model="item.choice"
                />
              </span>
            </td>
            <td>
              <span class="blog__item2__pic" v-if="editIndex !== index && item.imgurl != ''"><img :src="item.imgurl" width="200px" height="auto" />
                <a
                    class="remove-image"
                    @click="removePhotoUrlServer(item.id, item.choice, item.question_id, '')"
                    style="display: inline"
                    >&#215;</a
                  >
              </span>
              

              <span v-if="editIndex === index && urlImage != null"
                ><img :src="urlImage" width="200px" height="auto" />
              </span>
              <span width="200px" v-if="editIndex === index">
                <div
                  class="profile-userbuttons"
                  style="text-align: center; padding-top: 0px"
                >
                  <button
                    onclick="$('#photos_files').click()"
                    class="btn btn-danger btn-sm"
                    style="
                      font-size: 14px;
                      color: yellow;
                      background-color: blue;
                      text-align: center;
                    "
                  >
                    <i class="fa fa-photo"></i> เลือกรูป
                  </button>
                  <input
                    style="display: none"
                    type="file"
                    id="photos_files"
                    accept="image/*"
                    @change="selectImageFile"
                  />
                </div>
              </span>
             
            </td>

            <td>
              <span v-if="editIndex !== index">
                <button
                  @click="edit(item, index)"
                  class="btn btn-sm btn-outline-secondary mr-2"
                >
                  Edit
                </button>
                <button
                  @click="deleteChoiceDatabase(item.id)"
                  class="btn btn-sm btn-outline-secondary mr-2"
                >
                  Remove
                </button>
              </span>
              <span v-else>
                <button
                  class="btn btn-sm btn-outline-secondary mr-2"
                  @click="cancel(item)"
                >
                  Cancel
                </button>
                <button
                  class="btn btn-sm btn-outline-secondary mr-2"
                  @click="
                    saveChoiceDatabase(
                      item.id,
                      item.choice,
                      item.question_id,
                      item.imgurl
                    )
                  "
                >
                  Save
                </button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="col-3 offset-9 text-right my-3">
        <button
          v-show="!editIndex"
          @click="add"
          class="btn btn-sm btn-secondary"
        >
          Add item
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "Choices",

  filters: {
    money: (value) =>
      new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(value),
  },

  data() {
    return {
      question_id: this.$route.params.id,
      editIndex: null,
      originalData: null,
      items: [],
      selectFile: null,
      blobSelected: null,
      urlImage: null,
      tax: 10,
    };
  },
  created() {
    this.getChoiceDatabase();
    if (this.user.type != "admin") this.$router.push("/");
  },
  methods: {
    async selectImageFile(e) {
      console.log(e.target.files);
      const file = e.target.files[0];
      this.selectFile = file;
      this.urlImage = URL.createObjectURL(file);
      // read blob url from file data
      this.blobSelected = await this.readAsDataURL(file);
    },
    removePhotourl(item_index) {
      this.items[item_index].imgurl = "";
      this.selectFile = null;
      this.urlImage = null;
    },
    readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = function () {
          resolve(fr.result);
        };
        fr.readAsDataURL(file);
      });
    },
    add() {
      this.originalData = null;
      this.items.push({
        question_id: this.question_id,
        choice: "",
        imgurl: "",
      });
      this.editIndex = this.items.length - 1;
    },

    edit(item, index) {
      this.originalData = Object.assign({}, item);
      this.editIndex = index;
    },

    cancel(item) {
      //this.selectFile = null ;
      this.editIndex = null;

      if (!this.originalData) {
        this.items.splice(this.items.indexOf(item), 1);
        return;
      }

      Object.assign(item, this.originalData);
      this.originalData = null;
    },

    remove(item, index) {
      this.items.splice(index, 1);
    },

    save(item) {
      this.originalData = null;
      this.editIndex = item.code;
    },
    removePhotoUrlServer(id, choice, question_id, imgurl){
      this.blobSelected = null ; 
      console.log("url "+imgurl);
      this.saveChoiceDatabase(id, choice, question_id, imgurl) ;
    },
    saveChoiceDatabase(id, choice, question_id, imgurl) {
      this.$confirm("Are you sure to update choice?").then(() => {
        //do something...
        let request = {
          request: "create_choice",
          choice_id: id,
          choice: choice,
          question_id: question_id,
          imgblob:
            this.blobSelected != null
              ? this.blobSelected.split("base64,")[1]
              : "",
          imgurl: imgurl,
          token: "JINNSOLUTION_PBA2.0",
        };
        //console.log(request);
        axios
          .post("https://pba-thai.com/api/1.0/index.php", request, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);
            //if (response.data.result == "success")
            this.$router.go(0);
            //this.getChoiceDatabase();
            this.editIndex = null;
            //console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    getChoiceDatabase() {
      //do something...
      let request = {
        request: "get_choices",
        question_id: this.question_id,
        token: "JINNSOLUTION_PBA2.0",
      };
      //console.log(request) ;

      axios
        .post("https://pba-thai.com/api/1.0/index.php", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data) ;
          if (response.data.result == "success") {
            this.items = response.data.data;
            this.editIndex = null;
          }
          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteChoiceDatabase(choice_id) {
      this.$confirm("Are you sure to delete choice?").then(() => {
        //do something...
        let request = {
          request: "delete_choice",
          question_id: this.question_id,
          choice_id: choice_id,
          token: "JINNSOLUTION_PBA2.0",
        };
        axios
          .post("https://pba-thai.com/api/1.0/index.php", request, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            //console.log(response.data);
            if (response.data.result == "success") this.getChoiceDatabase();
            this.$router.go(0);
            this.editIndex = null;
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    subtotal(item) {
      return (
        item.qty * item.price - (item.qty * item.price * item.discount) / 100
      );
    },
  },

  computed: {
    ...mapGetters(["user"]),
    allSubTotal() {
      return this.items
        .map((item) => this.subtotal(item))
        .reduce((a, b) => a + b, 0);
    },

    total() {
      return this.tax
        ? this.allSubTotal + this.allSubTotal * (this.tax / 100)
        : this.allSubTotal;
    },
  },
};
</script>

<style>
.set-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.blog__item2__pic {
  width: 200px;
  height: auto;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.profile {
  margin: 20px 0;
  margin-right: 10px;
}

i {
  margin: 20px 10px;
}

/* Profile sidebar */
.profile-sidebar {
  padding: 20px 0 10px 0;
  background: #fff;
}

.profile-userpic img {
  float: none;
  margin: 0 auto;
  width: 50%;
  height: 50%;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}

.profile-usertitle {
  text-align: center;
  margin-top: 20px;
}

.profile-usertitle-name {
  color: #5a7391;
  font-weight: 600;
  margin-bottom: 7px;
}

.profile-usertitle-job {
  text-transform: uppercase;
  color: #5b9bd1;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
}

.profile-userbuttons {
  text-align: left;
  margin-top: 10px;
}

.profile-userbuttons .btn {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  padding: 6px 6px;
  margin-right: 5px;
  border-radius: 0.2rem;
}

.profile-userbuttons .btn:last-child {
  margin-right: 0px;
}

.profile-userbuttons i {
  margin: 5px;
}

.profile-usermenu {
  margin-top: 30px;
  display: block;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  z-index: 1;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  text-align: left;
}
.profile-usermenu a {
  text-align: left;
}

.profile-usermenu ul li {
  border-bottom: 1px solid #f0f4f7;
}

.profile-usermenu ul li:last-child {
  border-bottom: none;
}

.remove-image {
  display: none;
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: 0px;
  right: 0px;
}
.remove-image:active {
  background: #e54e4e;
  top: 3px;
  right: 3px;
}
button {
  width: 80%;
  margin: 5px;
  color: violet;
}
table {
  background-color: turquoise;
}
input[type="number"] {
  text-align: right;
}
</style>