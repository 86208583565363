<template>
  <div style="margin-top: 100px">
    <div class="container mt-4">
      <table class="table table-bordered" style="margin-top: 20px">
        <thead class="thead-light">
          <tr>
            <th width="1">#</th>
            <th width="20%">เลขที่สมาชิก</th>
            <th width="40%">ชื่อ</th>
            <th width="20%">เลือก</th>
            <th width="10%">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <span>{{ item.member_id }}</span>
            </td>
            <td>
              <span>{{ item.member_name }}</span>
            </td>
            <td>
              <span>{{
                item.choice_multiple != "" ? item.choice_multiple : item.choice
              }}
              <input v-if="item.choice_multiple != ''" v-model="item.choice_multiple" />
              <input v-else v-model="item.choice" />
                
              </span>
            </td>
            <td>
              <span
                ><button
                  style="height: 20px"
                  @click="
                    updateVote(
                      item.member_id,
                      item.member_name,
                      question_id,
                      item.choice_id,
                      item.choice_multiple
                    )
                  "
                ></button
              ></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "VoteResult",

  data() {
    return {
      question_id: this.$route.params.id,
      items: [],
      choiceshow: [],
    };
  },
  created() {
    this.getVoteResultDatabase();
    if (this.user.type != "admin") this.$router.push("/");
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    resultChoice() {
      this.choiceshow = [];
      for (let i = 0; i < this.items.length; i++) {
        let choiceSelectName = "";
        if (this.items[i].choice_multiple != "") {
          let jmultiple = JSON.parse(this.items[i].choice_multiple);
          for (let j = 0; j < jmultiple.length; j++)
            choiceSelectName = choiceSelectName + jmultiple[j].name + ",";
        }
        let ditem = {
          member_id: this.items[i].member_id,
          member_name: this.items[i].member_name,
          choice: this.items[i].choice,
          imgurl: this.items[i].imgurl,
          choice_multiple: choiceSelectName,
        };
        this.choiceshow.push(ditem);
      }
    },
    updateVote(
      member_id,
      member_name,
      question_id,
      choice_id,
      choice_multiple
    ) {
      let request = {
        request_function: "member_get",
        request_search_condition: "member",
        request_member_id: member_id,
        token: "JINNSOLUTION_PBA2.0",
      };
      //console.log(request);
      axios
        .post("https://photofairbangkok.com/pba-member/api/2.0/", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);

          let member = response.data[0];
          console.log(member_name + "  " + member.name);
          this.memberVote(
            member_id,
            member.name,
            question_id,
            choice_id,
            choice_multiple
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    memberVote(
      member_id,
      member_name,
      question_id,
      choice_id,
      choice_multiple
    ) {
      let request = {
        request: "member_vote2",
        member_id: member_id,
        member_name: member_name,
        question_id: question_id,
        choice_id: choice_id,
        choice_multiple: choice_multiple,
        //         imgphoto: this.uploadImg.split("base64,")[1],
        token: "JINNSOLUTION_PBA2.0",
      };
      //console.log(request);
      axios
        .post("https://pba-thai.com/api/1.0/", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.result == "success") {
            this.$alert("โหวต เสร็จแล้ว");
          } else {
            this.$alert("โหวต ส่ง data ไม่สำเร็จ หรือ internet มีปัญหา");
          }
        });
    },
    getVoteResultDatabase() {
      //do something...
      let request = {
        request: "get_voteresult",
        question_id: this.question_id,
        token: "JINNSOLUTION_PBA2.0",
      };
      //console.log(request);

      axios
        .post("https://pba-thai.com/api/1.0/index.php", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.result == "success") {
            this.items = response.data.data;
            this.resultChoice();
          }
          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.span-capture img {
  width: 100% !important;
  height: 100%;
  object-fit: contain;
}
table {
  background-color: turquoise;
}
input[type="number"] {
  text-align: right;
}
</style>