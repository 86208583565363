<template>
  <!-- Gallery -->
  <div class="container" style="margin-top: 20px">
    <div class="hero__search__form">
      <form>
        <div class="row">
          <div class="col-lg-6 col-6">
            <input
              v-on:keyup.enter="createYoutube(youtubecreate, youtubetitle)"
              v-model="youtubecreate"
              class="select__option3"
              type="text"
              placeholder="youtube URL"
            />
          </div>
          <div class="col-lg-6 col-6">
            <input
              v-on:keyup.enter="createYoutube(youtubecreate, youtubetitle)"
              v-model="youtubetitle"
              class="select__option3"
              type="text"
              placeholder="youtube title"
            />
          </div>
          <div class="col-lg-12 col-12">
            <button
              @click="createYoutube(youtubecreate, youtubetitle)"
              class="select__option3"
              type="button"
            >
              เพิ่ม Youtube
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-12">
      <div class="col-lg-12">
        <vx_CoolLightBox
          :items="youtubeModal"
          :index="indexYoutube"
          :closeOnClickOutsideMobile="true"
          :fullScreen="true"
          @close="indexYoutube = null"
        >
        </vx_CoolLightBox>
        <vx_Carousel
          v-if="foldername == ''"
          :per-page="3"
          :perPageCustom="[
            [340, 1],
            [600, 3],
          ]"
          :mouse-drag="true"
          class="coollight__slider"
        >
          <vx_Slide
            v-for="(youtube, ixytb) in youtubes"
            :key="ixytb"
            class="artist__item"
            :item="youtube.youtube"
          >
            <div class="card" style="text-align:center">
            <div
              class="blog__item2__pic set-bg"
              :style="{ 
                'width':'100%',
                'background-image':
                  'url(' + getVideoThumb(youtube.youtube) + ')',
              }"
            >
              <!-- @click="setYoutubeIndex(index)"  -->
              <!-- :href="youtube" -->
              <a @click="setYoutubeIndex(ixytb)" class="play-btn video-popup"
                ><i class="fa fa-play"></i
              ></a>
              <a
                class="remove-image"
                @click="removeYoutube(youtube.id)"
                style="display: inline"
                >&#215;</a
              >
              
            </div>
            <p>{{youtube.title}} </p>
            </div>
          </vx_Slide>
        </vx_Carousel>
      </div>
      <div class="row">
        <div class="col-lg-12" style="background-color: #a8a8a8; height: 40px">
          <i class="fa fa-folder"> {{ foldername }}</i>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="hero__search__form">
        <form>
          <div class="row">
            <div class="col-lg-6">
              <input
                v-on:keyup.enter="createFolder(foldercreate)"
                v-model="foldercreate"
                class="select__option3"
                type="text"
                placeholder="สร้างโฟดเดอร์"
              />
            </div>
            <div class="col-lg-3 col-6">
              <button
                @click="createFolder"
                class="select__option3"
                type="button"
              >
                สร้างโฟดเดอร์
              </button>
            </div>
            <div class="col-lg-3 col-6">
              <button
                onclick="$('#photos_files').click()"
                class="select__option3"
                type="button"
              >
                เลือกรูปอัพโลด
              </button>
            </div>
            <!-- <div class="col-lg-4">
              <button
                onclick="$('#photos_filesfolder').click()"
                class="select__option3"
                type="button"
              >
                เลือกโฟดเดอร์อัพโลด
              </button>
            </div> -->
            <div class="col-lg-6 col-6">
              <button @click="homeClick" class="select__option3" type="button">
                HOME
              </button>
            </div>
            <div class="col-lg-6 col-6">
              <button
                @click="previousFolder(foldername)"
                class="select__option4"
                type="button"
              >
                BACK
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2" style="display: none">
        <div class="profile-userbuttons">
          <input
            style="display: none"
            type="file"
            id="photos_files"
            accept="image/*"
            multiple
            @change="selectFile"
          />
        </div>
      </div>
      <div class="col-lg-2 col-md-2" style="display: none">
        <div class="profile-userbuttons">
          <input
            style="display: none"
            type="file"
            id="photos_filesfolder"
            webkitdirectory
            multiple
            accept="image/*"
            @change="selectFileFolder"
          />
        </div>
      </div>
    </div>
    <div class="row" style="width: 100%; margin-left: 0px">
      <div
        v-for="(image, i) in filelist"
        :key="i"
        @click="onClick(i)"
        class="col-lg-3 col-md-4 col-sm-6 col-6"
        style="margin-buttom: 20px"
      >
        <div class="card mb-3 card-img-top img-fluid">
          <img
            v-if="image.type == 'folder'"
            @click="folderClick(image.name)"
            :src="image.path"
            class="mw-100 shadow-1-strong rounded mb-4"
            alt="..."
          />
          <img
            v-else
            :src="image.path"
            class="mw-100 shadow-1-strong rounded mb-4"
            alt="..."
          />
          <div class="card-body" style="height: 80px">
            <p class="card-title" style="font-size: 14px">{{ image.name }}</p>
            <p
              v-if="image.type == 'file'"
              class="card-text"
              style="font-size: 14px"
            >
              {{ image.size }}
            </p>
            <!-- <a
              v-if="image.type == 'folder'"
              href="#!"
              @click="folderClick(image.name)"
              class="btn btn-primary"
              >Select Folder</a
            > -->
          </div>
          <a
            class="remove-image"
            @click="removeFileImages(image.name, image.type)"
            style="display: inline"
            >&#215;</a
          >
          <!-- <a
            v-if="image.type == 'file'"
            @click="downloadImg(image.path, image.name)"
            style="display: inline"
            ><i class="fa fa-download" aria-hidden="true"></i>
          </a>  -->
          <a
            v-if="image.type == 'file'"
            :href="image.path"
            style="display: inline"
            :download="image.name"
            ><i class="fa fa-download" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- The Modal -->
    <div id="myModal" class="modal">
      <span class="close">&times;</span>
      <img class="modal-content" id="img01" />
      <div id="caption"></div>
    </div>
  </div>
</template>
<script>
// Get the modal
var modal = document.getElementById("myModal");

// Get the image and insert it inside the modal - use its "alt" text as a caption
var img = document.getElementById("myImg");
var modalImg = document.getElementById("img01");
var captionText = document.getElementById("caption");
img.onclick = function () {
  modal.style.display = "block";
  modalImg.src = this.src;
  captionText.innerHTML = this.alt;
};

// Get the <span> element that closes the modal
var span = document.getElementsByClassName("close")[0];

// When the user clicks on <span> (x), close the modal
span.onclick = function () {
  modal.style.display = "none";
};
</script>

<script>
import { mapGetters } from "vuex";
import { Carousel, Slide } from "vue-carousel";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

import axios from "axios";
export default {
  components: {
    vx_Carousel: Carousel,
    vx_Slide: Slide,
    vx_CoolLightBox: CoolLightBox,
  },
  data() {
    return {
      youtubes: [],
      youtubeModal:[],
      youtubecreate: "",
      youtubetitle: "",
      indexYoutube: null,
      selectedFiles: [],
      foldercreate: "",
      foldername: "",
      videoObject: null,
      images: [
        "https://placekitten.com/801/800",
        "https://placekitten.com/802/800",
        "https://placekitten.com/803/800",
      ],
      filelist: [],
      index: null,
    };
  },
  created() {
    this.getFileImages();
    if (this.user.type != "admin") this.$router.push("/");
    if (this.foldername == "") this.getYoutubes();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    setYoutubeIndex(index) {
      this.indexYoutube = index;
    },
    getYoutubeId(url) {
      if (url != null) {
        url = url.split("v=");
        return url[1];
      } else return null;
    },
    getVideoThumb(url) {
      //let videoId = getIdFromURL(url);
      let videoId = this.getYoutubeId(url);
      //https://img.youtube.com/vi/OYr-KPboPDw/hqdefault.jpg
      let thumbnailUrl =
        "https://img.youtube.com/vi/" + videoId + "/hqdefault.jpg";
      return thumbnailUrl;
    },
    getVideoDetail(url) {
      let videoId = getIdFromURL(url);

      fetch("https://youtube.com/get_video_info?video_id=" + videoId, {
        method: "get",
      })
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
          console.log(jsonData);
          this.videoObject = jsonData.results;
          this.results = jsonData.results;
          return jsonData.results;
        });
      return "";
    },
    createYoutube(youtubecreate, youtubetitle) {
      if (youtubecreate != "" && youtubetitle != "")
        this.$confirm("Are you sure to create youtube").then(() => {
          //do something...
          let request = {
            request: "add_youtube",
            youtube_url: youtubecreate,
            youtube_title: youtubetitle,
            token: "JINNSOLUTION_PBA2.0",
          };
          //console.log(request) ;

          axios
            .post("https://pba-thai.com/api/1.0/index.php", request, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              console.log(response);
              if (response.data.result == "success") {
                this.getYoutubes();
                this.youtubecreate = "";
                this.youtubetitle = "" ; 
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        });
      else {
        this.$alert(" Youtube Url และ Title ว่างไม่ได้");
      }
    },
    removeYoutube(id) {
      //do something...
      this.$confirm("Are you sure to remove this youtube").then(() => {
        let request = {
          request: "del_youtube",
          youtube_id: id,
          token: "JINNSOLUTION_PBA2.0",
        };
        //console.log(request) ;

        axios
          .post("https://pba-thai.com/api/1.0/index.php", request, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
            if (response.data.result == "success") {
              this.getYoutubes();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    getYoutubes() {
      //do something...
      let request = {
        request: "get_youtubes",
        youtube_items: "xxx",
        token: "JINNSOLUTION_PBA2.0",
      };
      //console.log(request) ;

      axios
        .post("https://pba-thai.com/api/1.0/index.php", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.result == "success") {
            this.youtubeModal = [];
            for (let i=0 ; i < response.data.data.length ; i++)
            this.youtubeModal.push(response.data.data[i].youtube) ;
            this.youtubes = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    rndStr(len) {
      let text = " ";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    onClick(i) {
      this.index = i;
    },
    homeClick() {
      this.foldername = "";
      this.getFileImages();
    },
    previousFolder(url) {
      var str = url;
      var n = url.lastIndexOf("/");
      this.foldername = str.slice(0, n);
      if (n < 1) {
        this.foldername = "";
      }
      this.getFileImages();
    },
    folderClick(imgname) {
      this.foldername = this.foldername + "/" + imgname;
      this.getFileImages();
    },
    selectFile() {
      this.progressInfos = [];

      this.selectedFiles = event.target.files;
      // this.photos = [];
      // for (let i = 0; i < this.selectedFiles.length; i++) {
      //   this.photos.push(this.selectedFiles[i].name) ;
      // }
      //console.log(event.target.files);
      this.upload();
    },

    downloadImg(url, filename) {
      fetch(url)
        .then((response) => {
          const blob = response.blob();
        })
        .then((images) => {
          saveAs(images, filename);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });

      console.log("downloading", filename);
    },
    selectFileFolder() {
      this.progressInfos = [];

      this.selectedFiles = event.target.files;
      // this.photos = [];
      // for (let i = 0; i < this.selectedFiles.length; i++) {
      //   this.photos.push(this.selectedFiles[i].name) ;
      // }
      //console.log(event.target.files);
      this.uploadFolder();
    },
    upload() {
      let formData = new FormData();
      for (let i = 0; i < this.selectedFiles.length; i++) {
        let infofile = { name: this.selectedFiles[i].name, url: null };
        //console.log(this.selectedFiles[i]);
        //console.log(this.selectedFiles[i]);
        formData.append("files[" + i + "]", this.selectedFiles[i]);
        formData.append("foldername", this.foldername);
      }

      axios
        .post("https://pba-thai.com/api/1.0/upload.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // using function(response) { } got nothing return
          //console.log(response.data);
          if (response.data.message === "Image Uploaded") {
            this.getFileImages();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    uploadFolder() {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        let formData = new FormData();
        formData.append("foldername", this.foldername);
        formData.append("totalfile", this.selectedFiles.length);
        formData.append("files", this.selectedFiles[i]);
        axios
          .post("https://pba-thai.com/api/1.0/uploadfolder.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            //console.log(response.data);
            if (response.data.message === "Image Uploaded") {
              this.getFileImages();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        formData = null;
      }
    },
    getFileImages() {
      let formData = new FormData();
      let folder = this.foldername;
      formData.append("foldername", folder);
      formData.append("request", "folder");
      formData.append("token", "JINNSOLUTION_PBA2.0");
      let request = {
        request: "folder",
        token: "JINNSOLUTION_PBA2.0",
        foldername: this.foldername,
      };
      axios
        .post("https://pba-thai.com/api/1.0/index.php", request, {
          headers: {
            "Content-Type": "multipart/form-data",
            //"Content-Type": "application/json",
          },
        })
        .then((response) => {
          //console.log(response.data);
          this.filelist = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    removeFileImages(filename, type) {
      this.$confirm("Are you sure to delete file?").then(() => {
        //do something...
        let request = {
          request: type == "file" ? "removefile" : "removedir",
          token: "JINNSOLUTION_PBA2.0",
          filename: filename,
          foldername: this.foldername,
        };
        axios
          .post("https://pba-thai.com/api/1.0/index.php", request, {
            headers: {
              "Content-Type": "multipart/form-data",
              //"Content-Type": "application/json",
            },
          })
          .then((response) => {
            //console.log(response.data);
            this.filelist = response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    createFolder() {
      this.$confirm("Are you sure to create folder?").then(() => {
        //do something...
        let request = {
          request: "createdir",
          token: "JINNSOLUTION_PBA2.0",
          createfolder: this.foldercreate,
          foldername: this.foldername,
        };
        axios
          .post("https://pba-thai.com/api/1.0/index.php", request, {
            headers: {
              "Content-Type": "multipart/form-data",
              //"Content-Type": "application/json",
            },
          })
          .then((response) => {
            //console.log(response.data);
            this.filelist = response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
  },
};
</script>
<style scoped>
.coollight__slider {
  margin-top: 10px;
}

.artist__item {
  text-align: center;
  max-width: 800px;
  margin: 0 5px;
}
.blog__item2__pic {
  height: 270px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.blog__item2__pic .play-btn {
  height: 50px;
  width: 50px;
  background: #f03250;
  border-radius: 50%;
  line-height: 5px;
  text-align: center;
  /* font-size: 24px; */
  display: inline-block;
  color: #ffffff;
}

.coollight__slider button {
  height: 50px;
  width: 50px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  font-size: 20px;
  color: #ffffff;
  line-height: 55px;
  text-align: center;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
  position: absolute;
  top: 41%;
  left: 0;
}

/* ------*/
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {
  opacity: 0.7;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}

button {
  font-size: 16px;
}

@media (max-width: 400px) {
  .profile-usermenu li {
    display: inline-block;
  }
  i {
    /* width: 50px;
  height: 50px; */
    position: relative;
    margin: 20px 10px;
    margin-right: 10px;
    margin-left: 10px;
    fill: #fff;
  }

  .hero__search__form {
    margin-bottom: 30px;
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 5px;
  }

  .hero__search__form form {
    display: block;
    text-align: center;
  }
  form {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  .hero__search__form form input {
    width: 80%;
    float: left;
    font-size: 20px;
    color: #a8a8a8;
    padding-left: 30px;
    height: 40px;
    border: none;
  }

  .hero__search__form form input::-webkit-input-placeholder {
    color: #a8a8a8;
  }

  .hero__search__form form input::-moz-placeholder {
    color: #a8a8a8;
  }

  .hero__search__form form input:-ms-input-placeholder {
    color: #a8a8a8;
  }

  .hero__search__form form input::-ms-input-placeholder {
    color: #a8a8a8;
  }

  .hero__search__form form input::placeholder {
    color: #a8a8a8;
  }

  .hero__search__form form .select__option {
    position: relative;
    width: 22.5%;
    float: left;
    height: 40px;
  }

  .hero__search__form form .select__option:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background: #eeeeee;
    content: "";
    z-index: 1;
  }

  .hero__search__form form .select__option .nice-select {
    width: 100%;
    border: none;
    border-radius: 0;
    height: 40px;
    line-height: 56px;
  }

  .hero__search__form form .select__option .nice-select span {
    font-size: 20px;
    color: #323232;
  }

  .hero__search__form form .select__option .nice-select .list {
    border-radius: 0;
    margin-top: 0;
    width: 100%;
  }

  .hero__search__form form .select__option .nice-select:after {
    border-right: 7px solid #183456;
    border-top: 7px solid transparent;
    height: 6px;
    margin-top: 0;
    right: 18px;
    width: 10px;
    border-bottom: none;
    top: 40%;
  }

  .hero__search__form form input.txt {
    width: 19% !important;
    float: left;
    font-size: 20px;
    color: #a8a8a8;
    padding-left: 30px;
    height: 40px;
    border: none;
    margin-bottom: 5px;
  }

  .hero__search__form form .select__option2 {
    position: relative;
    width: 50%;
    padding: 2px;
    border-radius: 0.4rem;
    float: left;
    height: 40px;
    margin-bottom: 5px;
  }

  .hero__search__form form .select__option3 {
    position: relative;
    width: 20%;
    padding: 2px;
    border-radius: 0.4rem;
    float: left;
    height: 40px;
    margin-bottom: 5px;
  }

  .hero__search__form form .select__option2:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;

    /* width: 1px;
  background: #eeeeee; */
    content: "";
    z-index: 1;
  }

  .hero__search__form form .select__option2 .nice-select {
    width: 100%;
    border: none;
    border-radius: 0;
    height: 40px;
    line-height: 56px;
  }

  .hero__search__form form .select__option2 .nice-select span {
    font-size: 20px;
    color: #323232;
  }

  .hero__search__form form .select__option2 .nice-select .list {
    border-radius: 0;
    margin-top: 0;
    width: 100%;
  }

  .hero__search__form form .select__option2 .nice-select:after {
    border-right: 7px solid #183456;
    border-top: 7px solid transparent;
    height: 6px;
    margin-top: 0;
    right: 18px;
    width: 10px;
    border-bottom: none;
    top: 40%;
  }

  .hero__search__form form button {
    width: 15%;
    height: 40px;
    border: none;
    font-size: 14px;
    font-weight: 700;
    background: #f03250;
    color: #ffffff;
    text-transform: uppercase;
  }
}

@media (min-width: 400px) {
  .profile-usermenu li {
    display: inline-block;
    width: 100%;
  }
}

.hero__search__form {
  margin-bottom: 30px;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 5px;
}

.hero__search__form form {
  display: block;
  text-align: center;
}
form {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.hero__search__form form input {
  width: 80%;
  float: left;
  font-size: 20px;
  color: #a8a8a8;
  padding-left: 30px;
  height: 40px;
  border: none;
}

.hero__search__form form input::-webkit-input-placeholder {
  color: #a8a8a8;
}

.hero__search__form form input::-moz-placeholder {
  color: #a8a8a8;
}

.hero__search__form form input:-ms-input-placeholder {
  color: #a8a8a8;
}

.hero__search__form form input::-ms-input-placeholder {
  color: #a8a8a8;
}

.hero__search__form form input::placeholder {
  color: #a8a8a8;
}

.hero__search__form form .select__option {
  position: relative;
  width: 22.5%;
  float: left;
  height: 40px;
}

.hero__search__form form .select__option:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: #eeeeee;
  content: "";
  z-index: 1;
}

.hero__search__form form .select__option .nice-select {
  width: 100%;
  border: none;
  border-radius: 0;
  height: 40px;
  line-height: 56px;
}

.hero__search__form form .select__option .nice-select span {
  font-size: 20px;
  color: #323232;
}

.hero__search__form form .select__option .nice-select .list {
  border-radius: 0;
  margin-top: 0;
  width: 100%;
}

.hero__search__form form .select__option .nice-select:after {
  border-right: 7px solid #183456;
  border-top: 7px solid transparent;
  height: 6px;
  margin-top: 0;
  right: 18px;
  width: 10px;
  border-bottom: none;
  top: 40%;
}

.hero__search__form form input.txt {
  width: 19% !important;
  float: left;
  font-size: 20px;
  color: #a8a8a8;
  padding-left: 30px;
  height: 40px;
  border: none;
  margin-bottom: 5px;
}

.hero__search__form form .select__option2 {
  position: relative;
  width: 90%;
  padding: 2px;
  border-radius: 0.4rem;
  float: left;
  height: 40px;
  margin-bottom: 5px;
}

.hero__search__form form .select__option3 {
  position: relative;
  width: 100%;
  padding: 2px;
  border-radius: 0.4rem;
  float: left;
  height: 40px;
  margin-bottom: 5px;
}
.hero__search__form form .select__option4 {
  position: relative;
  width: 100%;
  padding: 2px;
  border-radius: 0.4rem;
  float: left;
  height: 40px;
  margin-bottom: 5px;
}

.hero__search__form form .select__option2:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;

  /* width: 1px;
  background: #eeeeee; */
  content: "";
  z-index: 1;
}

.hero__search__form form .select__option2 .nice-select {
  width: 100%;
  border: none;
  border-radius: 0;
  height: 40px;
  line-height: 56px;
}

.hero__search__form form .select__option2 .nice-select span {
  font-size: 20px;
  color: #323232;
}

.hero__search__form form .select__option2 .nice-select .list {
  border-radius: 0;
  margin-top: 0;
  width: 100%;
}

.hero__search__form form .select__option2 .nice-select:after {
  border-right: 7px solid #183456;
  border-top: 7px solid transparent;
  height: 6px;
  margin-top: 0;
  right: 18px;
  width: 10px;
  border-bottom: none;
  top: 40%;
}

.hero__search__form form button {
  width: 80%;
  height: 40px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  background: #f03250;
  color: #ffffff;
  text-transform: uppercase;
}

.profile {
  margin: 20px 0;
  margin-right: 10px;
}

i {
  margin: 20px 10px;
}

/* Profile sidebar */
.profile-sidebar {
  padding: 20px 0 10px 0;
  background: #fff;
}

.profile-userpic img {
  float: none;
  margin: 0 auto;
  width: 50%;
  height: 50%;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}

.profile-usertitle {
  text-align: center;
  margin-top: 20px;
}

.profile-usertitle-name {
  color: #5a7391;
  font-weight: 600;
  margin-bottom: 7px;
}

.profile-usertitle-job {
  text-transform: uppercase;
  color: #5b9bd1;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
}

.profile-userbuttons {
  text-align: left;
  margin-top: 10px;
}

.profile-userbuttons .btn {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  padding: 6px 6px;
  margin-right: 5px;
  border-radius: 0.2rem;
}

.profile-userbuttons .btn:last-child {
  margin-right: 0px;
}

.profile-userbuttons i {
  margin: 5px;
}

.profile-usermenu {
  margin-top: 30px;
  display: block;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  z-index: 1;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  text-align: left;
}
.profile-usermenu a {
  text-align: left;
}

.profile-usermenu ul li {
  border-bottom: 1px solid #f0f4f7;
}

.profile-usermenu ul li:last-child {
  border-bottom: none;
}
.remove-image {
  display: none;
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: 0px;
  right: 0px;
}
.remove-image:active {
  background: #e54e4e;
  top: 3px;
  right: 3px;
}
/*  *******/
.download-image {
  display: none;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.download-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: 0px;
  left: 0px;
}
.download-image:active {
  background: #e54e4e;
  top: 3px;
  left: 3px;
}
/*  */
.wrapper {
  position: relative;
  overflow: hidden;
}

.wrapper:after {
  content: "";
  display: block;
  padding-top: 100%;
}

.wrapper img {
  width: auto;
  height: 100%;
  max-width: none;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.ximg {
  width: auto;
  height: 200px;
}
</style>