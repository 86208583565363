import Vue from 'vue'
import App from './App.vue'
import VueSimpleAlert from 'vue-simple-alert';
Vue.use(VueSimpleAlert);

import WebVision from 'vue-cam-vision'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/css/main.css'

import store from '@/store'

Vue.config.productionTip = false
import router from '@/routers'

Vue.use(WebVision)
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
