<template>
  <div class="App">
    <div class="vertical-center">
      <div class="inner-block">
        <div
          v-if="user.type != 'admin' && user.type != 'member'"
          class="vue-tempalte"
        >
          <h3>สมาชิก</h3>

          <div class="form-group">
            <label>เลขสมาชิก</label>
            <input
              v-model="form.member_id"
              type="text"
              class="form-control form-control-lg"
            />
          </div>

          <div class="form-group">
            <label>รหัสผ่าน </label>
            <span
              v-if="hidepassword"
              class="display-eye fa fa-eye-slash pl-2"
              @click="hidepassword = !hidepassword"
            >
            </span>
            <span
              v-else
              class="display-eye fa fa-eye pl-2"
              @click="hidepassword = !hidepassword"
            >
            </span>
            <!-- <label>(ถ้ายังไม่ได้ตั้ง ให้ใช้เบอร์มือถือที่ให้สมาคม)</label> -->
            <input
              v-if="!hidepassword"
              v-model="form.member_passwd"
              type="text"
              class="form-control form-control-lg"
            />
            <input
              v-else
              v-model="form.member_passwd"
              type="password"
              class="form-control form-control-lg"
            />
          </div>

          <button
            @click="memberLogin"
            type="button"
            class="btn btn-dark btn-lg btn-block"
          >
            เข้าสู่ระบบ
          </button>
          <button
            @click="$router.push('Register')"
            type="button"
            class="btn btn-warning btn-lg btn-block"
          >
            ลงทะเบียน
          </button>

          <!--p class="forgot-password text-right">
            <router-link :to="{ name: 'Register' }"
              >ลงทะเบียน </router-link
            >
          </p-->
          <!-- <p class="forgot-password text-right">
            <router-link :to="{ name: 'SignUp' }"
              >ลงทะเบียน ลืม พาสเวิร์ด?</router-link
            >
          </p> -->
          <!-- <div class="social-icons">
              <ul>
                <li>
                  <a href="#"><i class="fa fa-google"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-facebook"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
              </ul>
            </div> -->
        </div>
        <div v-else class="vue-tempalte">
          <h3>ท่านได้ลงทะเบียนและเข้าระบบแล้ว</h3>
          <button
            @click="gotoMain"
            type="button"
            class="btn btn-dark btn-lg btn-block"
          >
            ไปหน้าแรก
          </button>
          <button
            @click="logoutMenu"
            type="button"
            class="btn btn-warning btn-lg btn-block"
          >
            ออกจากระบบ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      hidepassword: true,
      member: null,
      tmpmobile: null,
      form: {
        member_name: "",
        member_id: "",
        member_passwd: "",
        token: "JINNSOLUTION_PBA2.0",
      },
      video: {},
      front: true,
    };
  },
  created() {
    this.getExistedMember();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    gotoMain() {
      this.$router.push({ path: "/" });
    },
    logoutMenu() {
      this.$router.push({ path: "/signin" });
      this.$router.go(0);
      window.localStorage.removeItem("PBAMEMBER");
    },
    getExistedMember() {
      var storemember = window.localStorage.getItem("PBAMEMBER");
      this.form = storemember != null ? JSON.parse(storemember) : this.form;
    },
    memberLogin() {
      let invalidmsg = "ท่านไม่ได้ใส่ข้อมูล user และ  password";
      let loginfailmsg = " login ไม่ผ่าน";
      let loginsuccess = " login ผ่าน";

      if (this.form.member_id != "" && this.form.member_passwd != "") {
        let request = {
          request_function: "member_login",
          request_member_id: this.form.member_id,
          request_member_passwd: this.form.member_passwd,
          token: this.form.token,
        };
        axios
          .post("https://photofairbangkok.com/pba-member/api/3.0/", request, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.result == "notexisted") {
              this.$alert(loginfailmsg);
              //this.getMemberId();
            } else {
              if (
                response.data.result == "existed" &&
                response.data.data != null &&
                response.data.data[0] != null
              ) {
                this.$alert(loginsuccess);
                let loginMember = {
                  member_name: response.data.data[0].name,
                  member_id: this.form.member_id,
                  member_passwd: this.form.member_passwd,
                  type: "member",
                };
                window.localStorage.setItem(
                  "PBAMEMBER",
                  JSON.stringify(loginMember)
                );
                this.$router.push({ path: "/" });
                this.$router.go(0);
              } else {
                window.localStorage.removeItem("PBAMEMBER");
                this.$alert(loginfailmsg);
              }
            }
          });
      } else {
        this.$alert(invalidmsg);
      }
    },
    getMemberId() {
      this.form.otp = "";
      let loginsuccess = " login ผ่าน";
      let loginfailmsg = " login ไม่ผ่าน";
      var nomobilerecord =
        "ไม่มีเลขโทรศัพท์มือถือบันทึกให้ฐานข้อมูลสมาคม โปรดติดต่อเจ้าหน้าที่สมาคม";

      var incorrectmember = "เลขเมมเบอร์ไอดีไม่ตรง";

      let request = {
        request_function: "member_get",
        request_search_condition: "member",
        request_member_id: this.form.member_id,
        token: this.form.token,
      };
      axios
        .post("https://photofairbangkok.com/pba-member/api/2.0/", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data[0] != null) {
            this.member = response.data[0];
            if (this.member != null) {
              this.member.mobile = this.member.mobile.replaceAll("-", "");
              this.member.mobile = this.member.mobile.replaceAll(" ", "");
              this.tmpmobile = this.member.mobile.split(",");
              if (this.tmpmobile.length > 0) {
                for (let i = 0; i < this.tmpmobile.length; i++) {
                  if (this.tmpmobile[i] == this.form.member_passwd) {
                    this.$alert(loginsuccess);
                    let loginMember = {
                      member_name: this.member.name,
                      member_id: this.form.member_id,
                      member_passwd: this.form.member_passwd,
                      type: "member",
                    };
                    window.localStorage.setItem(
                      "PBAMEMBER",
                      JSON.stringify(loginMember)
                    );
                    this.$router.push({ path: "/" });
                    this.$router.go(0);
                    break;
                  } else {
                    if (i == this.tmpmobile.length - 1)
                      this.$alert(loginfailmsg);
                  }
                }
              } else {
                if (this.member.mobile != "") {
                  if (this.member.mobile == this.form.member_passwd) {
                    this.$alert(loginsuccess);
                    let loginMember = {
                      member_name: this.member.name,
                      member_id: this.form.member_id,
                      member_passwd: this.form.member_passwd,
                      type: "member",
                    };
                    window.localStorage.setItem(
                      "PBAMEMBER",
                      JSON.stringify(loginMember)
                    );
                    this.$router.push({ path: "/" });
                    this.$router.go(0);
                  } else {
                    this.$alert(loginfailmsg);
                  }
                } else {
                  this.$alert(nomobilerecord);
                }
              }
            }
          } else {
            this.$alert(incorrectmember);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.btn-block {
  margin-left: 0 !important;
}
</style>