<template>
  <div class="vertical-center">
    <div class="inner-block2" style="text-align: center">
      <span v-show="!isvote" style="text-align: center"
        ><h2>เลือก หัวข้อ</h2></span
      >
      <i
        v-show="isvote"
        @click="goBack"
        class="fa fa-arrow-left"
        style="font-size: 20px; text-align: center"
      ></i>
      <!-- <span ><button type="button" class="btn btn-outline-primary" style="text-align:center">GO BACK</button>
      </span> -->
    </div>
    <div
      v-if="!isvote"
      class="inner-block"
      style="margin-top: 5px; text-align: center"
    >
      <div v-for="(qvote, index) in items" :key="index">
        <p>
          <button
            class="btn btn-dark btn-lg btn-block"
            style="font-size: 16px !important"
            @click="landingVote(index, qvote)"
          >
            {{ qvote.question }}
          </button>
          <a style="font-size: 12px !important; text-align: center"
            >ช่วงเวลา {{ qvote.start_time }} - {{ qvote.end_time }}</a
          >
        </p>
      </div>
    </div>
    <vote-query
      v-if="isvote"
      :select="isvote"
      :question="currentVote"
    ></vote-query>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VoteQuery from "@/components/VoteQuery";
import axios from "axios";

export default {
  name: "Voting",
  components: {
    VoteQuery,
  },
  data() {
    return {
      currentVote: null,
      isvote: false,
      items: [],
      errorStr: null,
    };
  },

  async created() {
    //do we support geolocation
    await this.getQuestionDatabase();
    if (this.user.type != "admin" && this.user.type != "member")
      this.$router.push("/signin");
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    goBack() {
      this.isvote = false;
    },
    landingVote(index, question) {
      this.getChoiceDatabase(question);
      this.isvote = true;
    },
    getQuestionDatabase() {
      //do something...
      let request = {
        request: "current_questions",
        token: "JINNSOLUTION_PBA2.0",
      };
      //console.log(request);
      axios
        .post("https://pba-thai.com/api/1.0/index.php", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.result == "success") {
            //console.log(response.data.data);
            this.items = response.data.data;
          }
          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getChoiceDatabase(question) {
      //do something...
      let request = {
        request: "get_choices",
        question_id: question.id,
        token: "JINNSOLUTION_PBA2.0",
      };
      //console.log(request);

      axios
        .post("https://pba-thai.com/api/1.0/index.php", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.result == "success") {
            this.currentVote = {
              question: question,
              choice: response.data.data,
            };
          }
          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.span-capture {
  width: 50% !important;
  float: left !important;
}
.span-capture img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
