<template>
  <div class="vertical-center container mt-4">
  
    <div class="inner-block" style="text-align: center">
      <img class="person_img" :src="messageUrl" alt="" />

      <h2 style="padding-top: 10px;">{{ message }}</h2>

      <h4>{{ member_no }}</h4>
      <h4>{{ company }}</h4>
      <h4>{{ province }}</h4>
    </div>
    <div class="inner-block"> 
      <img class="reward_img" src="https://www.pba-thai.com/member/img/38135.jpg" />
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      countDownSec: 10,
      messageUrl: "",
      message: "-                         -",
      member_no:"",
      company_name:"",
      province_name:"",
      registerList: [],
      timeIntrv: 0,
      counter: 0,
    };
  },
  created() {
    this.refreshRegisterList();
    if (parseInt(this.$route.query.timesecond) > 0)
      this.countDownSec = this.$route.query.timesecond;
    if (this.user.type != "admin") this.$router.push("/");
  },
  // computed: {
  //   randomList() {
  //     if (this.registerList !== []) {
  //       return this.registerList.sort().filter((item, pos, ary) => {
  //         return !pos || item.id !== ary[pos - 1].id;
  //       });
  //     }
  //     return [];
  //   },
  // },
  methods: {
    refreshRegisterList() {
      this.search_member_id = "";
      //do something... member_get
      let request = {
        request: "member_register",
        condition: "all",
        token: "JINNSOLUTION_PBA2.0",
      };
      axios
        .post("https://pba-thai.com/api/1.0/", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.result != "error") {
            //console.log(response.data);
            this.registerList = response.data.data;
            //this.registerList = JSON.parse(response.data.data) ;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    rndStr() {
      let randx = this.registerList.sort().filter((item, pos, ary) => {
        return !pos || item.member_no !== ary[pos - 1].member_no;
      });
      return randx[Math.floor(Math.random() * randx.length)];
    },
    countDownSecTimer() {
      if (this.countDownSec > 0) {
        setTimeout(() => {
          this.countDownSec -= 1;
          this.countDownSecTimer();
        }, 1000);
      } else {
        clearInterval(this.timeIntrv);
      }
    },
  },
  watch: {
    //  counter() {
    //    if (this.counter > this.timecounter) clearInterval(this.timeIntrv);
    // },
  },
  mounted() {
    //let modfactor = 3;
    this.countDownSecTimer();
    this.timeIntrv = setInterval(() => {
      let message = this.rndStr();
      //this.counter++;
      //if (this.counter % modfactor === 0) {
      this.message = message.name;
      this.member_no = message.member_no;
      this.company = message.company_name;
      this.province = message.province_name;
      if (
        this.messageUrl != "" &&
        this.messageUrl != "NULL" &&
        this.messageUrl != null && 
        (this.messageUrl.endsWith("jpg") ||
          this.messageUrl.endsWith("JPG") ||
          this.messageUrl.endsWith("png") ||
          this.messageUrl.endsWith("PNG") ||
          this.messageUrl.endsWith("jpeg") ||
          this.messageUrl.endsWith("JPEG")) ||
          this.messageUrl.endsWith("gif")
      )
        this.messageUrl =
          "https://www.photofairbangkok.com/pba-member/images/member/" +
          message.photo;
      else
        this.messageUrl =
          "https://www.photofairbangkok.com/pba-member/images/member/avatar.png";
      //modfactor = modfactor + 2;
      //}
      //if (counter > 200) clearInterval();
    }, 300);
  },
};
</script>
<style scoped>
.random {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.person_img {
  width: 150px;
  height: 180px;
  text-align: center;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.5);
  /* transition: width 1s; */
}
.reward_img {
  width: 300px;
  /* text-align: center; */
  padding: 10px;
  border-radius: 2px;
  justify-content: center;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.5);
}
</style>