<template>
  <div class="vue-tempalte" style="margin-top:0px">
    <!-- Navigation -->
    <Header> </Header>
    <router-view />
    <!-- Main -->
  </div>
</template>

<script>
//import WebVision from './components/WebVision.vue'
import Header from "./components/Header";
export default {
  name: "app",
  components: {
    Header,
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
