<template>
  <div>
    <!-- <label v-if="errorStr"> Error: </label> -->
    <!-- <p style="text-align: center; font-size: 8px">{{ msg }} {{ errorStr }}</p> -->

    <div v-if="renderComponent" class="inner-block">
      <h4>{{ question.question.question }}</h4>
      <div
        v-for="(choice, index) in question.choice"
        :key="index"
        class="form-check"
      >
        <input
          class="form-check-input"
          type="checkbox"
          v-model="mChoices[index]"
          @change="changeCheckBox(index)"
        />
        <label class="form-check-label">
          {{ choice.choice }}
        </label>
        <span v-if="choice.imgurl != ''"
          ><div v-if="isMobile">
            <img
              @click="switchModal(index)"
              :class="[itemModal == index ? activeClass : '']"
              :style="modalCssMobile(index)"
              :src="choice.imgurl"
              width="200px"
              height="auto"
            />
            <button
              @click="switchModal(index)"
              :style="modalCssMobile(index)"
              v-if="itemModal == index"
              id="x"
            >
              กดปิด
            </button>
          </div>
          <div v-else>
            <img
              @click="switchModal(index)"
              :class="[itemModal == index ? activeClass : '']"
              :style="modalCssPC(index)"
              :src="choice.imgurl"
              width="200px"
              height="auto"
            />
            <button
              @click="switchModal(index)"
              :style="modalCssMobile(index)"
              v-if="itemModal == index"
              id="x"
            >
              กดปิด
            </button>
          </div>
        </span>
      </div>
      <div v-if="onVote" class="row">
        <button
          @click="onCapture"
          type="button"
          class="btn btn-dark btn-lg btn-block"
        >
          เลือก
        </button>
      </div>
      <!-- <p class="forgot-password text-right">**{{ aChoice.choice != null ? aChoice.choice : "" }}</p>  -->
    </div>
    <!-- <div class="inner-block" style="margin-top: 10px">
      <div class="row">
        <div class="span-capture">
          <img class="span-capture" :src="img" />
        </div>
        <div id="camDiv" class="span-capture">
          <WebCam
            ref="webcam"
            :deviceId="deviceId"
            width="100%"
            height="100%"
            @cameras="onCameras"
            @camera-change="onCameraChange"
            :isFrontCam="frontCam"
            :googleKey="googleKey"
          >
          </WebCam>
        </div>
      </div> 
      <div v-if="onVote" class="row">
        <button
          @click="onCapture"
          type="button"
          class="btn btn-dark btn-lg btn-block"
        >
          เลือก
        </button>
      </div>
    </div> -->
  </div>
</template>

<script>
//import { WebCam } from "vue-cam-vision";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "VoteQuery",
  props: {
    question: Object,
    select: Boolean,
    msg: String,
  },
  data() {
    return {
      renderComponent: true,
      itemModal: null,
      modalMode: false,
      lastItemChoices: null,
      mChoices: [],
      mChoicesPro: [],
      aChoice: null,
      totalSelect: 0,
      onVote: false,
      getCamera: false,
      captures: [],
      imgReport: [],
      frontCam: false,
      webcam: null,
      img: null,
      uploadImg: null,
      sizeInKb: null,
      camera: null,
      deviceId: null,
      devices: [],
      location: null,
      gettingLocation: false,
      errorStr: null,
      googleKey: "AIzaSyB7LXdvsXpgxdgMgW21sgOm7xSONC0ISwE",
    };
  },
  components: {
    //  WebCam,
  },
  watch: {
    mChoices: function () {
      //this.changeCheckBox();
    },
    aChoice: function () {
      this.onVote = true;
    },
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      //const [first, ..tail] = this.devices;
      const [first] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },
  beforeMount() {
    //  for (let i = 0; i < this.question.choice.length; i++) {
    //    this.mChoices.push(false);
    //  }
  },
  created() {
    for (let i = 0; i < this.question.choice.length; i++) {
      this.mChoices.push(false);
    }
    // if (
    //   "mediaDevices" in navigator &&
    //   "getUserMedia" in navigator.mediaDevices
    // ) {
    //   console.log("Let's get this party started");
    // }
    // navigator.mediaDevices.getUserMedia({ video: true });
    // if (this.user.type != "admin" && this.user.type != "member")
    //   this.$router.push("/");
    // if (!("geolocation" in navigator)) {
    //   this.errorStr = "Geolocation is not available.";
    //   this.$alert(this.errorStr);
    //   return;
    // }
    // navigator.mediaDevices.getUserMedia(
    //   (constraints) => {
    //     console.log(constraints);
    //     this.getCamera = true;
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
    // this.gettingLocation = true;
    // // get position
    // navigator.geolocation.getCurrentPosition(
    //   (pos) => {
    //     this.gettingLocation = false;
    //     this.location = pos;
    //   },
    //   (err) => {
    //     this.gettingLocation = false;
    //     this.errorStr = err.message;
    //   }
    // );
  },

  computed: {
    ...mapGetters(["isMobile", "user"]),

    camDivH() {
      let result = document.getElementById("camDiv").clientHeight;
      //console.log(result);
      return result;
    },
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
    canGeolocate() {
      return !!navigator.geolocation;
    },
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    changeCheckBox(index) {
      this.lastItemChoices = index;
      let total = 0;
      for (let i = 0; i < this.question.choice.length; i++) {
        if (this.mChoices[i]) total = 1 + total;
      }
      if (total > parseInt(this.question.question.maxmultiple)) {
        this.mChoices[this.lastItemChoices] = false;
        total = total - 1;
        this.$alert(
          "ผู้ใช้เลือกคำตอบเกินกว่าที่กำหนด คำตอบเลือกได้ เท่ากับ " +
            this.question.question.maxmultiple
        );
        this.forceRerender();
      } else {
        this.mChoicesPro = [];
        for (let i = 0; i < this.question.choice.length; i++) {
          if (this.mChoices[i]) this.mChoicesPro.push({id:this.question.choice[i].id,name:this.question.choice[i].choice});
        }
      }
      this.totalSelect = total;
      if (
        this.totalSelect > 0 &&
        this.totalSelect <= this.question.question.maxmultiple
      )
        this.onVote = true;
      else this.onVote = false;
    },
    goBack() {
      this.select = !this.select;
    },
    switchModal(itemid) {
      if (this.modalMode) {
        this.itemModal = itemid;
      } else {
        this.itemModal = null;
      }
      this.modalMode = !this.modalMode;
    },
    modalCssMobile(itemid) {
      if (this.itemModal == itemid) {
        this.modalMode = true;
        return {
          position: "fixed",
          top: "5%",
          left: "0",
          width: "100%",
          height: "auto",
          margin: "0",
          "margin-bottom": "0!important",
          padding: "0",
          "z-index": "1000",
          "text-align": "center",
        };
      } else {
        this.modalMode = false;
        return {};
      }
    },
    modalCssPC(itemid) {
      if (this.itemModal == itemid) {
        this.modalMode = true;
        return {
          position: "fixed",
          display: "block",
          top: "0",
          left: "10%",
          width: "auto",
          height: "80%",
          margin: "0",
          "overflow-y": "auto",
          "margin-bottom": "0!important",
          padding: "0",
          "z-index": "10000",
          "text-align": "top",
        };
      } else {
        this.modalMode = false;
        return {};
      }
    },
    memberVote() {
      let invalidmsg = "ระบบยังไม่สามารถ Vote ได้ หรือท่านอาจ login เป็น admin";
      if (this.user.type == "member" && this.user.member_id != "") {
        let request = {
          request: "member_vote2",
          member_id: this.user.member_id,
          member_name: this.user.member_name,
          question_id: this.question.question.id,
          choice_id: this.mChoicesPro[0].id,
          choice_multiple: JSON.stringify(this.mChoicesPro),
          //         imgphoto: this.uploadImg.split("base64,")[1],
          token: "JINNSOLUTION_PBA2.0",
        };
        //console.log(request);
        axios
          .post("https://pba-thai.com/api/1.0/", request, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.result == "success") {
              this.$alert("โหวต เสร็จแล้ว");
              this.$router.push("/");
            } else {
              this.$alert("โหวต ส่ง data ไม่สำเร็จ หรือ internet มีปัญหา");
            }
          });
      } else {
        this.$alert(invalidmsg);
      }
    },
    // camera
    async onCapture() {
      // this.img = await this.$refs.webcam.capture();
      // var stringLength = this.img.length - "data:image/png;base64,".length;
      // var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
      // this.sizeInKb = sizeInBytes / 1000;

      // this.resizeBase64Img(this.img, 100, 100   ).then((newImg) => {
      //   this.uploadImg = newImg ;
      //   if (this.uploadImg != null) this.memberVote();
      // });

      this.memberVote();
    },
    resizeBase64Img(base64, newWidth, newHeight) {
      return new Promise((resolve, reject) => {
        console.log(reject);
        var canvas = document.createElement("canvas");
        canvas.style.width = newWidth.toString() + "px";
        canvas.style.height = newHeight.toString() + "px";
        canvas.style.objectFit = "cover";
        let context = canvas.getContext("2d");
        let img = document.createElement("img");
        img.style.width = newWidth.toString() + "px";
        img.style.height = newHeight.toString() + "px";
        img.style.objectFit = "contain";
        img.src = base64;

        img.onload = function () {
          context.scale(newWidth / img.width, newHeight / img.height);
          context.drawImage(img, 0, 0);
          resolve(canvas.toDataURL());
        };
      });
    },
    onStarted(stream) {
      console.log("On Started Event", stream);
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
    },
    async onStop() {
      await this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      console.log("On Camera Change Event", deviceId);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#x {
  position: absolute;
  background: red;
  color: white;
  top: -10px;
  width: 30px;
  height: auto;
  right: -10px;
}
closeButton {
  position: "fixed";
  z-index: "20000";
  top: "0";
  left: "0";
  width: "30px";
  height: "auto";
  margin: "0";
  padding: "0";
  text-align: "center";
}
activeClass {
  position: "fixed";
  top: "0";
  left: "0";
  width: "100%";
  height: "auto";
  margin: "0";
  padding: "0";
  text-align: "center";
}
.span-capture {
  width: 50% !important;
  float: left !important;
}
.span-capture img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
