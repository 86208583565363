<template>
  <div class="App">
    <div class="vertical-center">
      <div class="inner-block">
        <div class="vue-tempalte">
          <form>
            <h3>Confirm OTP</h3>

            <div class="form-group">
              <label>เลขที่สมาชิก</label>
              <input
                v-model="name"
                type="text"
                class="form-control form-control-lg"
              />
            </div>

            <div class="form-group">
              <label>เลข OTP</label>
              <input
                @keypress="onlyForNumber"
                v-model="form.otp"
                type="number"
                class="form-control form-control-lg"
              />
            </div>
            <div v-if="correctOTP" class="form-group">
              <label>พาสเวอร์</label>
              <input
                v-model="form.password1"
                type="password"
                class="form-control form-control-lg"
              />
            </div>
            <div v-if="correctOTP" class="form-group">
              <label>คอนเฟิมพาสเวอร์</label>
              <input
                v-model="form.password2"
                type="password"
                class="form-control form-control-lg"
              />
            </div>
            <div class="row">
              <button type="submit" class="btn btn-dark btn-lg btn-block">
                Confirm OTP
              </button>
              <button type="button" class="btn btn-dark btn-lg btn-block">
                Resend OTP
              </button>
            </div>
            <p class="forgot-password text-right">
              Already registered
              <router-link :to="{ name:'SingIn' }">sign in?</router-link>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestoreref } from "@/config/firebaseConfig";

export default {
  data() {
    return {
      form: {
        otp: "",
      },
      error: null,
    };
  },
  methods: {
    onlyForNumber($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.form.idcard.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      } else {
        this.form.idcard.slice(0, -1);
      }

      // restrict to 2 decimal places
      if (
        this.form.idcard != null &&
        this.form.idcard.indexOf(".") > -1 &&
        this.form.idcard.split(".")[1].length > 1
      ) {
        $event.preventDefault();
      }
    },
    submit() {
      firestoreref
        .auth()
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then((data) => {
          data.user
            .updateProfile({
              displayName: this.form.name,
            })
            .then(() => {});
        })
        .catch((err) => {
          this.error = err.message;
        });
    },
  },
};
</script>