import axios from 'axios';
import router from '../routers';
//import mutations from 'mutations';

const actions = {

  fetchUsers: ({commit}) => {
    axios.get('/api.php?action=read')
      .then(res => {
        if (!res.error) {
          commit('FETCH_USERS', res.data.users);
        }
      })
      .catch(err => console.error(err));
  },
  addUser: ({commit}, user) => {
    const data = new FormData();
		for ( let key in user ) {
      data.append(key, user[key]);
    }

    axios.post('/api.php?action=create', data)
      .then(res => {
        if (!res.data.error) {
          commit('ADD_USER', user);
          commit('ADD_NEW_USER_ERROR', false);
          router.replace('/');
        } else {
          commit('ADD_NEW_USER_ERROR', res.data.message);
        }
      })
      .catch(err => console.error(err));
  },
  setError: ({commit}, error) => {
    commit('ADD_NEW_USER_ERROR', error);
  },
 
  deleteUser: ({commit}, id) => {
    const data = new FormData();
    data.append('id', id);

    axios.post('/api.php?action=delete', data)
      .then(res => {
        if (!res.data.error) {
          commit('DELETE_USER', id);
          commit('ADD_NEW_USER_ERROR', false);
        } else {
          commit('ADD_NEW_USER_ERROR', res.data.message);
        }
      })
      .catch(err => console.error(err));
  }
};

export default actions;

