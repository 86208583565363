<template>
  <div style="margin-top: 100px">
    <div class="container mt-4">
      <table class="table table-bordered" style="margin-top: 20px">
        <thead class="thead-light">
          <tr>
            <th width="1">#</th>
            <th width="20%">เลขที่สมาชิก</th>
            <th width="40%">ชื่อ</th>
            <th width="20%">เลือก</th>
            <th width="10%">รูป</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in choiceshow " :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <span>{{ item.member_id }}</span>
            </td>
            <td>
              <span>{{ item.member_name }}</span>
            </td>
            <td>
              <span>{{
                item.choice_multiple != ""
                  ? item.choice_multiple 
                  : item.choice
              }}</span>
            </td>
            <td>
              <img
                v-if="!item.imgurl.includes('***')"
                class="span-capture"
                :src="item.imgurl"
                alt=""
                width="50"
                height="50"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "VoteResult",

  data() {
    return {
      question_id: this.$route.params.id,
      items: [],
      choiceshow: [],
    };
  },
  created() {
    this.getVoteResultDatabase();
    if (this.user.type != "admin") this.$router.push("/");
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    resultChoice() {
      this.choiceshow = [];
      for (let i = 0; i < this.items.length; i++) {
        let choiceSelectName = "";
        if (this.items[i].choice_multiple != "" ) {
          let x = this.items[i].choice_multiple ;
          let jmultiple = JSON.parse(x); 
          for (let j = 0; j < jmultiple.length; j++)
            choiceSelectName = choiceSelectName+ jmultiple[j].name + ",";
        }
        let ditem = {
          member_id : this.items[i].member_id,
          member_name : this.items[i].member_name,
          choice : this.items[i].choice,
          imgurl : this.items[i].imgurl,
          choice_multiple : choiceSelectName ,
        }
        this.choiceshow.push(ditem);
      }
    },
    getVoteResultDatabase() {
      //do something...
      let request = {
        request: "get_voteresult",
        question_id: this.question_id,
        token: "JINNSOLUTION_PBA2.0",
      };

      axios
        .post("https://pba-thai.com/api/1.0/index.php", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response) ;
          if (response.data.result == "success") {
            this.items = response.data.data;
            this.resultChoice();
          }
          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.span-capture img {
  width: 100% !important;
  height: 100%;
  object-fit: contain;
}
table {
  background-color: turquoise;
}
input[type="number"] {
  text-align: right;
}
</style>