<template>
  <!-- Navigation -->
  <div>
    <nav
      v-if="!isMobile"
      class="
        navbar
        shadow
        bg-white
        rounded
        justify-content-between
        flex-nowrap flex-row
        fixed-top
      "
    >
      <div class="container">
        <a class="navbar-brand float-left" href="" target="_blank"> </a>
        <ul class="nav navbar-nav flex-row float-right">
          <li class="nav-item">
            <router-link class="btn btn-outline-primary" to="/"
              >สมาคม</router-link
            >
          </li>
          <li v-if="user.member_id != ''" class="nav-item">
            <router-link class="btn btn-outline-primary" to="/voting"
              >โหวต</router-link
            >
          </li>
          <li v-if="user.member_id != ''" class="nav-item">
            <router-link class="btn btn-outline-primary" to="/voteresult"
              >ผลโหวต</router-link
            >
          </li>
          <li v-if="user.type == 'member'" class="nav-item">
            <router-link class="btn btn-outline-primary" to="/gallery"
              >แกลเลอรี</router-link
            >
          </li>
          <!-- <li v-if="user.type == 'admin'" class="nav-item">
            <router-link class="btn btn-outline-primary" to="/admin/otp"
              >รายการส่ง OTP</router-link
            >
          </li> -->
          <li v-if="user.type == 'admin'" class="nav-item">
            <router-link class="btn btn-outline-primary" to="/admin/question"
              >คำถามโหวต</router-link
            >
          </li>
          <li v-if="user.type == 'admin'" class="nav-item">
            <router-link class="btn btn-outline-primary" to="/admin/gallery"
              >แกลเลอรี</router-link
            >
          </li>
          <li v-if="user.type == 'admin'" class="nav-item">
            <router-link
              class="btn btn-outline-primary"
              to="/admin/registerlist"
              >รายการลงทะเบียน</router-link
            >
          </li>
          <li v-if="user.type == 'member'" class="nav-item">
            <!-- <span style="font-size:10px">{{user.member_id}}</span> -->
            <p class="btn btn-outline-primary" @click="changePasswd">
              เปลี่ยนรหัสผ่าน
            </p>
          </li>

          <li
            v-if="user.type == 'member' || user.type == 'admin'"
            class="nav-item"
          >
            <p class="btn btn-outline-primary" @click="logoutMenu">ล็อกเอาท์</p>
          </li>

          <li v-if="user.type == ''" class="nav-item">
            <router-link class="btn btn-outline-primary" to="/signin"
              >เข้าสู่ระบบ</router-link
            >
          </li>
          <li v-else class="nav-item mt-2 ml-1 mr-1 mb-0">
            <span>{{ user.member_name }}</span>
          </li>
          <!-- <li v-if="user.type == ''" class="nav-item">
            <router-link class="btn btn-outline-primary" to="/signup"
              >ลงทะเบียน</router-link
            >
          </li> -->
          <li v-if="user.type == ''" class="nav-item">
            <router-link class="btn btn-outline-primary" to="/register"
              >ลงทะเบียน</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="btn btn-outline-primary" to="/questionanswer"
              >คำถามตอบ</router-link
            >
          </li>
        </ul>
      </div>
    </nav>
    <header>
      <div v-if="isMobile" class="topnav">
        <div class="mobile_logo">
          <a href="./index.html"
            ><img class="logo" src="@/assets/logo.png" alt=""
          /></a>
        </div>
        <div class="mobilemenu" id="mobileLinks">
          <a href="/">สมาคม </a>
          <a v-if="user.member_id != ''" href="/voting">โหวต </a>
          <a v-if="user.member_id != ''" href="/voteresult">ผลโหวต </a>
          <a v-if="user.type == 'member'" href="/gallery">แกลเลอรี </a>
          <a v-if="user.type == 'admin'" href="/admin/gallery"
            >แกลเลอรี (Admin)
          </a>
          <!-- <a v-if="user.type == 'admin'" href="/admin/otp">รายการส่ง OTP </a> -->
          <a v-if="user.type == 'admin'" href="/admin/question">คำถามโหวต </a>
          <a v-if="user.type == 'admin'" href="/admin/registerlist"
            >รายการลงทะเบียน
          </a>
          <a v-if="user.type == 'member'" @click="changePasswd"
            >เปลี่ยนรหัสผ่าน
          </a>
          <a
            v-if="user.type == 'member' || user.type == 'admin'"
            @click="logoutMenu"
            >ล็อกเอาท์
          </a>

          <a v-if="user.type == ''" href="/signin">เข้าสู่ระบบ </a>
          <!-- <a v-if="user.type == ''" href="/signup">ลงทะเบียน </a> -->
          <a v-if="user.type == ''" href="/register">ลงทะเบียน </a>
          <a href="/questionanswer">คำถามตอบ </a>
        </div>
        <a v-if="user.type != ''" class="name" style="margin-top: 0px">
          {{ user.member_name }}</a
        >
        <a class="icon" @click="mobileMenu()">
          <i class="fa fa-bars" style="margin-top: 0px"></i>
        </a>
      </div>
    </header>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["isMobile", "user"]),
    querystring() {
      return "?p=" + Math.random() * 100;
    },
  },
  methods: {
    logoutMenu() {
      this.$router.push({ path: "/signin" });
      this.$router.go(0);
      window.localStorage.removeItem("PBAMEMBER");
    },
    changePasswd() {
      this.$router.push({ path: "/changepasswd" });
    },
    //mobile: this.$store.getters.isMobile
    mobileMenu() {
      var x = document.getElementById("mobileLinks");
      if (x.style.display === "block") {
        x.style.display = "none";
      } else {
        x.style.display = "block";
      }
    },
  },
};
</script>
<style scoped>
ul li:hover > ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}
.mobilemenu {
  color: white;
}
.mobile_logo {
  height: 50px;
}
.mobile_logo .logo {
  height: 50px;
  width: 50px;
  background-color: #333;
}
.topnav {
  overflow: hidden;
  background-color: #333;
  position: relative;
}

/* Hide the links inside the navigation menu (except for logo/home) */
.topnav #mobileLinks {
  display: none;
}

/* Style navigation menu links */
.topnav a {
  color: white;
  padding: 15px 15px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}

.topnav a img {
  color: white;
  padding: 0px 0px;
  text-decoration: none;
  font-size: 17px;
  display: none;
  /* display: block; */
}
/* Style the hamburger menu */
.topnav a.icon {
  /* background: black; */
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.topnav a.name {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

/* Add a grey background color on mouse-over */
.topnav a:hover {
  background-color: #ddd;
  /* color: black; */
  display: block;
}
/* Style the active link (or home/logo) */
.active {
  background-color: #4caf50;
  color: white;
}
</style>