
let storeuser = window.localStorage.getItem('PBAMEMBER');

const state = {
	user: storeuser ? JSON.parse(storeuser) : {
		member_id: '',
		member_passwd: '',
		type : ''
	},
	
}

const mutations = {
	SET_LOGGED_IN(state, value) {
		state.user.loggedIn = value;
	},
	SET_USER(state, user) {
		state.user.loggedIn = (user !== null);

		if (user) {
			state.user = user;
			window.localStorage.setItem('PBAMEMBER', JSON.stringify(state.user));
		}
	},
	REMOVE_USER(){
		let user = {loggedIn: false , data : null } ; 
		window.localStorage.setItem('PBAMEMBER',JSON.stringify(user)) ; 
	},
}
const actions = {

	setUser({ commit }, user) {
		commit("SET_LOGGED_IN", user !== null);

		if (user) {
			commit("SET_USER", user);
		} else {
			commit("SET_USER", null);
		}
	},
	getUser(){
		return state.user ; 
	},
}

const getters = {
	user() {
		return state.user ;
	},
}


export default {
	mutations,
	actions,
	getters,
}