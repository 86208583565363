//import Vue from 'vue' ;
import firebase from 'firebase/app' ;
import 'firebase/firestore'  ;
//import 'firebase/app' 
import 'firebase/auth' ;
//import store from '@/vuexmodel/store' ;

const config = {
    apiKey: "AIzaSyAaaW1V5W7BHU8jkzbi7Ru3zyztFYa0brs",
    authDomain: "ak-scope.firebaseapp.com",
    databaseURL: "https://ak-scope.firebaseio.com",
    projectId: "ak-scope",
    storageBucket: "ak-scope.appspot.com",
    messagingSenderId: "115108385735",
    appId: "1:115108385735:web:d90e6e84800bdafee7bfe2"
};

firebase.initializeApp(config);

//export const firebaseref = firebase.database().ref() ;
export const firestoreref = firebase.firestore() ; 
export const firebaseAuth = firebase.auth;
export const fire = firebase ; 

//Vue.prototype.$firebase = firebase ; 
export default firebase  ; 
//export const provider = new firebase.GoogleAuthProvider();
