<template>
  <div class="container mt-4">
    <h>BOOKING</h>
    <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th width="1">#</th>
          <th width="20%">วันที่ส่ง</th>
          <th width="15%">เมมเบอร์ไอดี</th>
          <th width="15%">เบอร์</th>
          <th width="10%">OTP</th>
          <th width="20%">สถานนะ</th>
          <th width="100%">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(sms, index) in displayedSMSs" :key="index">
          <td>{{ perPage * (page - 1) + index + 1 }}</td>
          <td>
            <span v-if="editIndex !== index">{{ sms.create_date }}</span>
          </td>

          <td>
            <span v-if="editIndex !== index">{{ sms.member_id }}</span>
          </td>
          <td>
            <span v-if="editIndex !== index">{{ sms.mobile }}</span>
          </td>
          <td>
            <span v-if="editIndex !== index">{{ sms.otp }}</span>
          </td>
          <td>
            <span v-if="editIndex !== index">{{ sms.status_message }}</span>
          </td>
          <td>
            <span v-if="editIndex !== index">
              <button
                @click="edit(item, index)"
                class="btn btn-sm btn-outline-secondary mr-2"
                style="background-color: white"
              >
                Edit
              </button>
              <button
                @click="remove(item, index)"
                class="btn btn-sm btn-outline-secondary mr-2"
                style="background-color: white"
              >
                Remove
              </button>
            </span>
            <span v-else>
              <button
                class="btn btn-sm btn-outline-secondary mr-2"
                style="background-color: white"
                @click="cancel(item)"
              >
                Cancel
              </button>
              <button
                class="btn btn-sm btn-outline-secondary mr-2"
                style="background-color: white"
                @click="save(item)"
              >
                Save
              </button>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <nav aria-label="Page navigation example">
      <ul class="pagination">
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page != 1"
            @click="page--"
          >
            Previous
          </button>
        </li>
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-for="(pageNumber, ix) in pages.slice(page - 1, page + 5)"
            :key="ix"
            @click="page = pageNumber"
          >
            {{ pageNumber }}
          </button>
        </li>
        <li class="page-item">
          <button
            type="button"
            @click="page++"
            v-if="page < pages.length"
            class="page-link"
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "SMSList",
  data() {
    return {
      page: 1,
      perPage: 9,
      pages: [],
      smslist: [],
      editIndex: null,
      originalData: null,

      tax: 10,
    };
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
    money: (value) =>
      new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(value),
  },
  created() {
    this.refreshSMSList();
    if (this.user.type != "admin") this.$router.push("/");
  },
  computed: {
    ...mapGetters(["user"]),
    displayedSMSs() {
      return this.paginate(this.smslist);
    },
  },
  watch: {
    smslist() {
      this.setPages();
    },
  },
  methods: {
    setPages() {
      let numberOfPages = Math.ceil(this.smslist.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(smslist) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return smslist.slice(from, to);
    },
    refreshSMSList() {
      //do something... member_get
      let request = {
        request_function: "sms_update",
        token: "JINNSOLUTION_PBA2.0",
      };
      axios
        .post("https://photofairbangkok.com/pba-member/api/2.0/", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data !=null)
          //console.log(response.data);
          this.getSMSList();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getSMSList() {
      //do something... member_get
      let request = {
        request_function: "sms_status",
        request_condition: "ALL",
        token: "JINNSOLUTION_PBA2.0",
      };
      axios
        .post("https://photofairbangkok.com/pba-member/api/2.0/", request, {
          headers: {
            "Content-Type": "multipart/form-data",
            //"Content-Type": "application/json",
          },
        })
        .then((response) => {
          //console.log(response.data);
          this.smslist = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>

table td {
  background-color: #566b57;
  color: white;
}
input[type="number"] {
  text-align: right;
}
.page-item{
  display: inline-flex;
}
button.page-link {
  display: inline-flex;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
</style>