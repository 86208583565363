<template>
  <div class="container mt-4" style="padding-top: 50px">
    <div class="row">
      <div class="col-8">
        <div class="form-group">
          <label>คำถาม</label>
          <input
            v-model="search_question_name"
            type="text"
            class="form-control form-control-lg"
          />
        </div>
      </div>
      <div class="col-4">
        <button
          @click="searchQuestion(search_question_name)"
          class="btn btn-sm btn-outline-secondary mr-1"
          style="background-color: white"
        >
          Search
        </button>
        <button
          @click="refreshQuestionList"
          class="btn btn-sm btn-outline-secondary mr-1"
          style="background-color: white"
        >
          Reset
        </button>
      </div>
    </div>
    <table class="table table-bordered" style="font-size: 12px">
      <thead class="thead-light">
        <tr>
          <th width="1">#</th>
          <th width="50%">คำถามโหวต</th>
          <th width="20%">เวลาโหวต</th>
          <th width="100%">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(question, index) in displayedQuestion" :key="index">
          <td>{{ perPage * (page - 1) + index + 1 }}</td>
          <td>
            <span v-if="editIndex !== index">{{ question.question }}</span>
          </td>
          <td>
            <span v-if="editIndex !== index">{{ question.start_time }}</span>
          </td>          
          <td>
            <span v-if="editIndex !== index">
              <button
                @click="viewQuestionEdit(question.id)"
                class="btn btn-sm btn-outline-secondary mr-1"
                style="background-color: white"
              >
                Edit
              </button>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <nav aria-label="page">
      <ul class="pagination">
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page != 1"
            @click="page--"
          >
            Previous
          </button>
        </li>
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-for="(pageNumber, ix) in pages.slice(page - 1, page + 5)"
            :key="ix"
            @click="page = pageNumber"
          >
            {{ pageNumber }}
          </button>
        </li>
        <li class="page-item">
          <button
            type="button"
            @click="page++"
            v-if="page < pages.length"
            class="page-link"
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
    <div class="col-3 offset-9 text-right my-3">
      <button
        @click="addQuestion"
        class="btn btn-sm btn-secondary"
        style="margin-top: 30px"
      >
        Add item
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "questionList",
  data() {
    return {
      page: 1,
      perPage: 9,
      pages: [],
      questionList: [],
      editIndex: null,
      originalData: null,
      search_question_name: "",
      tax: 10,
    };
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
    money: (value) =>
      new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(value),
  },
  created() {
    this.refreshQuestionList();
    if (this.user.type != "admin") this.$router.push("/");
  },
  computed: {
    ...mapGetters(["user"]),
    displayedQuestion() {
      return this.paginate(this.questionList);
    },
  },
  watch: {
    questionList() {
      this.setPages();
    },
  },
  methods: {
    viewQuestionEdit(id) {
      this.$router.push("/admin/question/question/" + id);
    },
    onlyForMember($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    setPages() {
      let numberOfPages = Math.ceil(this.questionList.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    addQuestion() {
       this.$router.push("/admin/questionnew");
    },
    paginate(questionList) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return questionList.slice(from, to);
    },
    deleteMemberRegisterPbaThai(member_id) {
      this.$confirm("Are you sure to delete from register?").then(() => {
        let request = {
          request: "member_registerdelete",
          token: "JINNSOLUTION_PBA2.0",
          request_member_id: member_id,
        };
        //console.log(request);
        axios
          .post("https://pba-thai.com/api/1.0/", request, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            //console.log(response.data)
            if (response.data.result == "success") {
              this.refreshQuestionList();
              //this.$alert('Delete done');
            }
          });
      });
    },
    refreshQuestionList() {
      //do something...
      let request = {
        request: "all_questions",
        token: "JINNSOLUTION_PBA2.0",
      };
      axios
        .post("https://pba-thai.com/api/1.0/index.php", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.result != "error") {
            //console.log(response.data);
            this.questionList = response.data.data;
            //this.questionList = JSON.parse(response.data.data) ;
          }

          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    searchQuestion(member_id) {
      //do something... member_get
      let request = {
        request: "member_register",
        condition: member_id,
        token: "JINNSOLUTION_PBA2.0",
      };
      axios
        .post("https://pba-thai.com/api/1.0/", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.result != "error") {
            //console.log(response.data);
            this.questionList = response.data.data;
            //this.questionList = JSON.parse(response.data.data) ;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
table td {
  background-color: #566b57;
  color: white;
}
input[type="number"] {
  text-align: right;
}
.page-item {
  display: inline-flex;
}
button.page-link {
  display: inline-flex;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
</style>