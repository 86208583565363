let screenwidth = window.screen.width;

const state = {
	isMobile: screenwidth < 700  
}
const getters = {
	isMobile: (state) => {
		return state.isMobile ; 
	},
}

export default {
	state,
	getters,
}