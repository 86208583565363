<template>
  <div class="container mt-4" style="padding-top: 50px">
    <div class="row">
      <div class="col-8">
        <div class="form-group">
          <label>เลขที่สมาชิก</label>
          <input
            v-model="search_member_id"
            type="text"
            class="form-control form-control-lg"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <button
              @click="searchMemberId(search_member_id)"
              class="btn btn-sm btn-outline-secondary mr-1"
              style="background-color: white"
            >
              Search
            </button>
          </div>
          <div class="col-lg-6 col-sm-12">
            <button
              @click="refreshRegisterList"
              class="btn btn-sm btn-outline-secondary mr-1"
              style="background-color: white"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-sm-12 span" >
          <label>ระยะเวลาวินาที</label>
          <input
            style="vertical-align: center; width:60px; display: inline-block;"
            @keypress="onlyForMember"
            v-model="timesecond"
            type="text"
          />
          </div>
          <div class="col-lg-6 col-sm-12 span" >
            <button
              @click="randomList"
              class="btn btn-sm btn-outline-secondary"
              style="vertical-align: top;background-color: white "
            >
              Random
            </button>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-bordered" style="font-size: 12px">
      <thead class="thead-light">
        <tr>
          <th width="1">#</th>
          <th width="15%">วันที่เป็นสมาชิก</th>
          <th width="10%">เมมเบอร์ไอดี</th>
          <th width="15%">ชื่อ</th>
          <th width="15%">เบอร์โทร</th>
          <th width="30%">ที่อยู่</th>
          <th width="10%">สถานนะ</th>
          <th width="100%">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(register, index) in displayedRegister" :key="index">
          <td>{{ perPage * (page - 1) + index + 1 }}</td>
          <td>
            <span v-if="editIndex !== index">{{ register.member_date }}</span>
          </td>

          <td>
            <span v-if="editIndex !== index">{{ register.member_no }}</span>
          </td>
          <td>
            <span v-if="editIndex !== index">{{ register.name }}</span>
          </td>
          <td>
            <span v-if="editIndex !== index">{{ register.mobile }}</span>
          </td>
          <td>
            <span v-if="editIndex !== index">{{ register.address }}</span>
          </td>
          <td>
            <span v-if="editIndex !== index">{{ register.status }}</span>
          </td>
          <td>
            <span v-if="editIndex !== index">
              <button
                @click="deleteMemberRegisterPbaThai(register.member_no)"
                class="btn btn-sm btn-outline-secondary mr-1"
                style="background-color: white"
              >
                Remove
              </button>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <nav aria-label="page">
      <ul class="pagination">
         <li class="page-item">
          <button
            type="button"
            @click="page = 1"
            v-if="page < pages.length"
            class="page-link"
          >
            Start
          </button>
        </li>
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page != 1"
            @click="page--"
          >
            Prev
          </button>
        </li>
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-for="(pageNumber, ix) in pages.slice(page - 1, page + 5)"
            :key="ix"
            @click="page = pageNumber"
          >
            {{ pageNumber }}
          </button>
        </li>
        <li class="page-item">
          <button
            type="button"
            @click="page++"
            v-if="page < pages.length"
            class="page-link"
          >
            Next
          </button>
        </li>
        <li class="page-item">
          <button
            type="button"
            @click="page = pages.length -1"
            v-if="page < pages.length"
            class="page-link"
          >
            End
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "RegisterList",
  data() {
    return {
      page: 1,
      perPage: 9,
      timesecond: 20,
      pages: [],
      registerList: [],
      editIndex: null,
      originalData: null,
      search_member_id: "",
      tax: 10,
    };
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
    money: (value) =>
      new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(value),
  },
  created() {
    this.refreshRegisterList();
    if (this.user.type != "admin") this.$router.push("/");
  },
  computed: {
    ...mapGetters(["user"]),
    displayedRegister() {
      return this.paginate(this.registerList);
    },
  },
  watch: {
    registerList() {
      this.setPages();
    },
  },
  methods: {
    randomList() {
      this.$router.push({ path: "/admin/randomlist" ,query: { timesecond: this.timesecond }});
    },
    onlyForMember($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    setPages() {
      let numberOfPages = Math.ceil(this.registerList.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(registerList) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return registerList.slice(from, to);
    },
    deleteMemberRegisterPbaThai(member_id) {
      this.$confirm("Are you sure to delete from register?").then(() => {
        let request = {
          request: "member_registerdelete",
          token: "JINNSOLUTION_PBA2.0",
          request_member_id: member_id,
        };
        //console.log(request);
        axios
          .post("https://pba-thai.com/api/1.0/", request, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            //console.log(response.data)
            if (response.data.result == "success") {
              this.refreshRegisterList();
              //this.$alert('Delete done');
            }
          });
      });
    },
    refreshRegisterList() {
      this.search_member_id = "";
      //do something... member_get
      let request = {
        request: "member_register",
        condition: "all",
        token: "JINNSOLUTION_PBA2.0",
      };
      axios
        .post("https://pba-thai.com/api/1.0/", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.result != "error") {
            //console.log(response.data);
            this.registerList = response.data.data;
            //this.registerList = JSON.parse(response.data.data) ;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    searchMemberId(member_id) {
      this.page = 1 ; 
      //do something... member_get
      let request = {
        request: "member_register",
        condition: member_id,
        token: "JINNSOLUTION_PBA2.0",
      };
      axios
        .post("https://pba-thai.com/api/1.0/", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.result != "error") {
            //console.log(response.data);
            this.registerList = response.data.data;
            //this.registerList = JSON.parse(response.data.data) ;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
table td {
  background-color: #566b57;
  color: white;
}
input[type="number"] {
  text-align: right;
}
.page-item {
  display: inline-flex;
}
button.page-link {
  display: inline-flex;
}
button.page-link {
  font-size: 15px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
</style>