<template>
  <div class="container voteresult mt-4" style="padding-top:5px" >
    <div
      v-for="(item, index) in items"
      :key="index"
      class="row"
      style="margin-top:5px!importance,margin-bottom: 5px;background-color:turquoise;"
    >
      <div v-if="sevenDayVoting(item.end_time)" class="col-lg-8 col-sm-8">
        <div class="col-lg-12" >
          <label  style="margin-top: 10px; font-weight:bold">วาระโหวต :</label><span style="font-size:16px;font-weight:bold"> {{ item.question }}</span>
        </div>
        <div class="col-lg-12" >
          <label style="margin-top: 10px; font-weight:bold">เวลาเริ่มต้น : </label><span style="font-size:14px"> {{ item.start_time }}</span>
        </div>
        <div class="col-lg-12" >
          <label style="margin-top: 10px; font-weight:bold">เวลาสิ้นสุด : </label><span style="font-size:14px"> {{ item.end_time }}</span>
        </div>
      </div>
      <div v-if="sevenDayVoting(item.end_time)"  class="col-lg-4  col-sm-4 col-12 timevote" style="text-align:center">
        <span v-if="endVoting(item.end_time)" style="margin-top:20px">
                    <button
            @click="viewGraphResult(item.id)"
            class="btn btn-sm btn-secondary mr-2"
          >
            Graph
          </button>
        
        </span>
        <span v-else style="margin-top:20px">
          <h3> ยังไม่จบการโหวต </h3>
        </span>
      </div>
    </div>
    <!-- <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th width="1">#</th>
          <th width="40%">คำถาม</th>
          <th width="10%">เวลาเริ่มต้น</th>
          <th width="10%">เวลาสิ้นสุด</th>
          <th width="100%">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ index + 1 }}</td>
          <td>
            <span v-if="editIndex !== index">{{ item.question }}</span>
            <span v-if="editIndex === index">
              <input
                class="form-control form-control-sm"
                v-model="item.question"
              />
            </span>
          </td>
          <td>
            <span v-if="editIndex !== index">{{ item.start_time }}</span>
            <Datepicker
              v-if="editIndex == index"
              v-model="item.start_time"
              format="YYYY-MM-DD H:i:s"
              width="80%"
            />
          </td>
          <td>
            <span v-if="editIndex !== index">{{ item.end_time }}</span>
            <Datepicker
              v-if="editIndex == index"
              v-model="item.end_time"
              format="YYYY-MM-DD H:i:s"
              width="80%"
            />
          </td>

          <td>
            <span v-if="editIndex !== index">
              <button
                @click="edit(item, index)"
                class="btn btn-sm btn-outline-secondary mr-2"
              >
                Edit
              </button>
              <button
                @click="editChoice(item.id)"
                class="btn btn-sm btn-outline-secondary mr-2"
              >
                Edit Choices
              </button>
              <button
                @click="deleteQuestionDatabase(item.id)"
                class="btn btn-sm btn-outline-secondary mr-2"
              >
                Remove
              </button>
            </span>
            <span v-else>
              <button
                class="btn btn-sm btn-outline-secondary mr-2"
                @click="cancel(item)"
              >
                Cancel
              </button>
              <button
                class="btn btn-sm btn-outline-secondary mr-2"
                @click="
                  saveQuestionDatabase(
                    item.id,
                    item.start_time,
                    item.end_time,
                    item.question
                  )
                "
              >
                Save
              </button>
            </span>
          </td>
        </tr>
      </tbody>
    </table> -->

    
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "VotingResult",
  data() {
    return {
      editIndex: null,
      originalData: null,
      items: [],
    };
  },
  created() {
    this.getQuestionDatabase();
    if (this.user.type != "admin" && this.user.type != "member"  ) this.$router.push("/signin");
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    endVoting(endtime){
        let now = new Date() ; 
        //console.log(now.getTime());
        //console.log(Date.parse(endtime));
        return now.getTime() > Date.parse(endtime)
    },
    sevenDayVoting(endtime){
        var d = new Date();
        d.setDate(d.getDate() - 7);
        return d.getTime() < Date.parse(endtime)
    },
    mysqlDate() {
      let date = new Date().toISOString().slice(0, 19).replace("T", " ");
      return date;
    },
    viewTableResult(id){
      this.$router.push("/admin/question/table/"+id);
    },    
    viewGraphResult(id){
      this.$router.push("/admin/question/graph/"+id);
    },
     getQuestionDatabase() {
      //do something...
      let request = {
        request: "all_questions",
        token: "JINNSOLUTION_PBA2.0",
      };
      axios
        .post("https://pba-thai.com/api/1.0/index.php", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.result == "success") {
            this.items = response.data.data;
            this.editIndex = null;
          }
          //console.log(response.data);
        })
        .catch(function (error) {
         console.log(error);
        });
    },
    subtotal(item) {
      return (
        item.qty * item.price - (item.qty * item.price * item.discount) / 100
      );
    },
  },
};
</script>

<style>
@media (min-width:600px) {
  .voteresult {
    margin-top:70px!important
  }
  .timevote{
    margin-top:30px!important
  }
}
.row {
  margin-top:5px;
}
button {
  width: 80%;
  margin: 5px;
}
.span-capture img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}

table {
  background-color: turquoise;
}
input[type="number"] {
  text-align: right;
}
</style>