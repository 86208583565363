<template>
  <div v-if="isMobile" class="vertical-center">
    <div class="inner-block2" style="text-align: center">
      <VueFaqAccordion :items="myItems" :borderColor="FFFFFF" />
    </div>
  </div>
  <div v-else>
    <div
      class="inner-block3"
      style="text-align: center; background-color: white"
    >
      <VueFaqAccordion :items="myItems" :borderColor="FFFFFF" />
    </div>
  </div>
</template>

<script>
import VueFaqAccordion from "vue-faq-accordion";

import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["isMobile", "user"]),
  },
  components: {
    VueFaqAccordion,
  },
  data() {
    return {
      myItems: [
        {
          title: "จะสมัครสมาชิกอย่างไร?",
          value:
            "ผู้ที่จะเป็นสมาชิกสามัญของสมาคม ต้องเป็นเจ้าของกิจการเกี่ยวกับ ธุรกิจการถ่ายภาพ 	และยื่นใบสมัคร พร้อมเอกสารประกอบ 1 ใบทะเบียนพาณิชย์ ที่ระบุว่า ทำธุรกิจเกี่ยวกับการถ่าย	ภาพ 2 สำเนาทะเบียนบ้าน 3 สำเนาบัตรประชาชน พร้อม โอนเงิน 2100 (QR​ บัญชีธนาคาร)​ และ	กรอกแบบฟอร์ม ใบสมัคร(ลิงค์ให้ดาวน์โหลด)​",
          category: "คำถามทั่วไป",
        },
        {
          title: "สมาคม มีไว้เพื่ออะไร?",
          value:
            "สมาคมธุรกิจการถ่ายภาพ เป็นสมาคม ตามพรบ สมาคมการค้า 2509 ซึ่งมีวัตถุประสงค์ ใน	การส่งเสริมวิสาหกิจ เกี่ยวกับการถ่ายภาพ",
          category: "คำถามทั่วไป",
        },
        {
          title: "เป็นสมาชิก แล้วได้อะไร?",
          value:
            "การเข้าเป็นสมาชิกสมาคม ขั้นต้น จะได้รับวารสาร ของสมาคม และได้ รู้จัก เพื่อนร่วม	อาชีพทั่วประเทศ นอกจากนั้น ยังมี การจัดอบรม เพื่อเพิ่มศักยภาพ ในการให้บริการประชาชน ซึ่ง 	จะเป็นการพัฒนาธุรกิจ ให้ทันสมัย ตลอดเวลา",
          category: "คำถามทั่วไป",
        },
        {
          title: "เป็นช่างภาพ แต่ไม่ได้เปิดร้าน สมัครสมาชิกได้ไหม?",
          value:
            "เป็นผู้ทำงาน เกี่ยวข้องกับ ธุรกิจถ่ายภาพ แต่ไม่ได้เป็น เจ้าของกิจการ สามารถสมัคร	สมาชิก ประเภทสมทบ ได้ครับ",
          category: "คำถามทั่วไป",
        },
        {
          title: "แล้วสมาชิกสมทบต่างจากสมาชิกสามัญอย่างไร?",
          value:
            "สมาชิกสมทบ ค่าสมัคร ปีแรก 600 และต่อไปปีละ 500 มีแต่ประเภทรายปีเท่านั้น มีสิทธิ	ต่างๆเหมือนกัน ยกเว้น ไม่มีสิทธิ์ สมัครเป็นกรรมการ หรือ ลงคะแนนเสียงเลือกตั้ง หรือลงมติอื่นๆ 	ในการประชุมใหญ่",
          category: "คำถามทั่วไป",
        },
        {
          title: "พ่อเคยเป็นสมาชิก และตายไปแล้ว ลูกจะเป็นแทนได้ไหม?",
          value:
            "การเป็นสมาชิก เป็นสิทธิ์เฉพาะตัว ไม่สามารถตกทอดเป็นมรดกได้ และ เมื่อสมาชิก ตาย 	ย่อมสิ้นสภาพเป็นสมาชิก ดังนั้น ลูกมารับกิจการต่ออยากเป็นสมาชิก ก็ต้องสมัครสมาชิกใหม่",
          category: "คำถามทั่วไป",
        },
        {
          title: "สมาชิกประเภทนิติบุคคล ผู้บริหาร ตาย สมาชิกภาพจะสิ้นสุดลงไหม?",
          value:
            "กรณีนิติบุคคล หากยังดำเนินกิจการอยู่ จะไม่สิ้นสภาพ การเป็นสมาชิก ผู้บริหาร คนใหม่ 	จะตั้งตัวแทน สมาชิก มาทำหน้าที่ หรือใช้สิทธิ์ ได้ 1 เสียง",
          category: "คำถามทั่วไป",
        },
        {
          title: "สมาคม เป็นองค์กรไม่แสวงหากำไร ทำไมถึงจัดโฟโต้แฟร์กำไรได้?",
          value:
            "คำว่าองค์กรไม่แสวงหากำไร นั้นหมายถึง ไม่แสวงหากำไรแบ่งปันกัน การที่สมาคมจัด	กิจกรรมแล้วมีกำไร คงเป็นเงินทุนส่วนรวม ในการส่งเสริมและพัฒนาธุรกิจต่อไป ไม่สามารถเอามา	แบ่งให้	สมาชิกหรือกรรมการได้ ต่างจากบริษัทหรือห้างหุ้นส่วน ที่ผู้เป็นหุ้นส่วนจะมีวัตถุประสงค์ใน	การแสวงหากำไร เพื่อแบ่งปันกันระหว่างหุ้นส่วน",
          category: "คำถามทั่วไป",
        },
        {
          title: "สมาคมการค้าตามพรบสมาคมการค้า 2509 ต่างจากสมาคมธรรมดาอย่างไร?",
          value:
            "อย่างแรก สมาคมการค้า เป็นสมาคมที่มีวัตถุประสงค์ในการส่งเสริมวิสาหกิจ จึงต่างกับ	สมาคมธรรมดา ที่มีวัตถุประสงค์ เพียงแค่การ ร่วมกันทำกิจกรรมอันเดียวกัน อย่างที่ 2 สมาคมการค้าเป็นสมาคม ที่มีสมาชิกเป็นเจ้าของธุรกิจ ต่างจากสมาคมธรรมดา 	ที่ใครก็ได้ไม่ต้องทำธุรกิจ ก็สามารถเป็นสมาชิกได้ อย่างที่ 3 สมาคมธรรมดา อยู่ภายใต้กฎหมาย แพ่งและพาณิชย์ ซึ่งไม่มีวัตถุประสงค์ ใน	การส่งเสริมวิสาหกิจ เป็นแต่เป็นการรวมตัวเพื่อทำกิจกรรมอย่างเดียวกันร่วมกัน",
          category: "คำถามทั่วไป",
        },
        {
          title:
            "สมาคมธุรกิจการถ่ายภาพ เก็บค่าสมาชิก ตลอดชีพ 2,000 บาท แล้วในระยะยาว จะบริหาร	ต่อไปได้อย่างไร?",
          value:
            "ตลอดระยะเวลาที่ผ่านมา สมาคม มีเงินมาบริหาร จากการจัดกิจกรรม Photo Fair และเงิน	ส่วนที่กำไร จึงนำมาใช้ ทำกิจกรรมส่งเสริมวิสาหกิจสมาชิก",
          category: "คำถามทั่วไป",
        },
        {
          title:
            "ทุกปี สมาคมจัดงานเสวนา ชมรมทั่วประเทศ ทำให้ผู้ประกอบการ ทั่วประเทศ ได้มาพบปะ 	ปรึกษากัน สมาคมยังคงจะทำต่อไปหรือเปล่า?",
          value:
            "การจัดงานเสวนา ชมรมทั่วประเทศ มีประโยชน์ในการแลกเปลี่ยนความคิดเห็นกัน และ 	สร้างความสามัคคีของมวลสมาชิกทั้งประเทศ หากไม่เหลือบ่ากว่าแรง คณะกรรมการก็จะจัดทุกปี  	สำหรับปีนี้ ที่จังหวัดอุดร ถ้าทานอาการโควิด ดีขึ้น ก็จะจัดขึ้น โดยประสานกับชมรมจังหวัดอุดร",
          category: "คำถามทั่วไป",
        },
        {
          title: "การส่งเสริม และยกระดับมาตรฐาน ร้านถ่ายภาพ มีอะไรบ้าง?",
          value:
            "ถ้าไม่มี covid เราคง มีการจัดอบรมหลักสูตร ที่สมาชิกต้องการ หลายหลักสูตร และใน 	ขนาดนี้ ยังได้ขอเป็นองค์กรรับรอง กับสถาบันคุณวุฒิวิชาชีพ เพื่อสอบประเมิน รับรองมาตรฐาน  	ของผู้กอบประกอบอาชีพ ในร้าน ถ่ายรูปทั่วประเทศ",
          category: "คำถามทั่วไป",
        },
        {
          title: "ในสถานการณ์โควิด ทางสมาคมดำเนินการอย่างไรบ้าง?",
          value:
            "ขณะนี้ สมาคมกำลังดำเนินการ จัดทำโปรแกรม และเว็บไซต์ เพื่ออำนวยความสะดวกกับ	สมาชิก สนับสนุน การประกอบอาชีพของสมาชิก และ จัดประชุม ผ่าน Application Zoom จึงทำให้	การดำเนินงาน ไม่สะดุด",
          category: "คำถามทั่วไป",
        },
        {
          title:
            "เห็นสมาคมจัด รางวัลการเรียนดี ให้ลูก ของสมาชิก ยังคงดำเนินการอยู่หรือเปล่า?",
          value:
            "ยังดำเนินการอยู่ ขณะนี้ ได้รับ พิจารณา ของ ปีการศึกษา 2562 แล้ว จะไปมอบรางวัล คาด	ว่า จะเป็นงานเสวนา ที่จังหวัดอุดร",
          category: "คำถามทั่วไป",
        },
        {
          title:
            "สมาคม มีไว้เพื่ออะไร",
          value:
            "สมาคมธุรกิจการถ่ายภาพ เป็นสมาคม ตามพรบ สมาคมการค้า 2509 ซึ่งมีวัตถุประสงค์ ในการส่งเสริมวิสาหกิจ เกี่ยวกับการถ่ายภาพ ",
          category: "คำถามบ่อย",
        },                                                                  
      ],
    };
  },
};
</script>
<style scoped>
.inner-block3 {
  width: 700px;
  margin-bottom: 20px !important;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 0px;
  border-radius: 15px;
  transition: all 0.3s;
}
</style>
