<template>
  <div class="vertical-center">
    <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
    <div class="row" style="background-color:white; text-align:center">
      <label style=" margin-left:30px;"> จำนวนผู้โหวตทั้งหมด  :</label>
      <p> {{items.length }}</p>
    </div>
     <div v-for="chartitem in chartData" :key="chartitem" class="row" style="background-color:white; text-align:center">
      <label v-if="chartitem[0]!='โหวต'" style=" margin-left:30px;"> {{ chartitem[0] }}  จำนวน : </label>
      <p v-if="chartitem[0]!='โหวต'"> {{ chartitem[1] }}</p>

    </div>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "App",
  components: {
    GChart,
  },
  data() {
    return {
      question_id: this.$route.params.id,
      items: [],
      choices: [],
      chartData: [],
      chartOptions: {
        chart: {
          title: "Company Performance",
          subtitle: "Sales, Expenses, and Profit: 2014-2017",
        },
      },
    };
  },
  created() {
    this.getVoteResultDatabase();
    if (this.user.type != "admin" && this.user.type != "member"  ) this.$router.push("/");
  },
  computed:{
        ...mapGetters(["user"]),
  },
  methods: {
    getChoiceDatabase() {
      //do something...
      let request = {
        request: "get_choices",
        question_id: this.question_id,
        token: "JINNSOLUTION_PBA2.0",
      };
      //console.log(request) ;

      axios
        .post("https://pba-thai.com/api/1.0/index.php", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data) ;
          if (response.data.result == "success") {
            this.choices = response.data.data;
            for (let i = 0; i < this.choices.length; i++) {
              if (i == 0) {
                let bankData = [];
                this.chartData.push(bankData);
                this.chartData[0].push("โหวต");
                this.chartData[0].push("ผลโหวต");
              }
              //this.chartData[0].push(this.choices[i].choice);
              let temp = [];
              temp.push(this.choices[i].choice);
              let total = 0;
              if (this.items.length > 0) {
                for (let j = 0; j < this.items.length; j++) {
                  //if (this.items[j].choice_id == this.choices[i].id  ) {
                  //  total = total + 1;
                  //}
                  if (this.items[j].choice_multiple !='' ){
                    let mchoice = JSON.parse(this.items[j].choice_multiple) ; 
                    //console.log(mchoice) ;
                    for (let k = 0 ; k < mchoice.length ; k++){
                      if (mchoice[k].id == this.choices[i].id) {
                        total = total + 1; 
                      }
                    }
                  } else {
                    if (this.items[j].choice_id == this.choices[i].id  ) {
                      total = total + 1;
                    }
                  }
                }
                temp.push(total);
              } else {
                temp.push(0);
              }
              this.chartData.push(temp);
            }
          }
          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getVoteResultDatabase() {
      //do something...
      let request = {
        request: "get_voteresult",
        question_id: this.question_id,
        token: "JINNSOLUTION_PBA2.0",
      };
      //console.log(request) ;

      axios
        .post("https://pba-thai.com/api/1.0/index.php", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data) ;
          if (response.data.result == "success") {
            this.items = response.data.data;
            this.getChoiceDatabase();
          } else {
            this.getChoiceDatabase();
          }
          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
