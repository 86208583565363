<template>
  <div class="App">
    <div class="vertical-center">
      <div class="inner-block">
        <div class="vue-tempalte">
          <h3>Admin Sign In</h3>

          <div class="form-group">
            <label>User</label>
            <input
              v-model="form.user"
              type="text"
              class="form-control form-control-lg"
            />
          </div>

          <div class="form-group">
            <label>Password</label>
            <input
              v-model="form.passwd"
              type="password"
              class="form-control form-control-lg"
            />
          </div>

          <button
            @click="adminLogin"
            type="button"
            class="btn btn-dark btn-lg btn-block"
          >
            เข้าสู่ระบบ
          </button>
          <!-- <div class="social-icons">
              <ul>
                <li>
                  <a href="#"><i class="fa fa-google"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-facebook"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
              </ul>
            </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      form: {
        user: "",
        passwd: "",
        token: "JINNSOLUTION_PBA2.0",
      },
      video: {},
      front: true,
    };
  },
  created: {
    stopCamera() {
      return (
        this.video.srcObject &&
        this.video.srcObject.getTracks().map((t) => t.stop())
      );
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    adminLogin() {
      let invalidmsg = "ท่านไม่ได้ใส่ข้อมูล user และ  password";
      let loginfailmsg = "login ไม่ผ่าน" ;
      if (this.form.user != "" && this.form.passwd != "") {
        let request = {
          request_function: "admin_login",
          request_admin_user: this.form.user,
          request_admin_passwd: this.form.passwd,
          token: this.form.token,
        };
        axios
          .post("https://pba-thai.com/api/1.0/", request, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            //console.log(response.data);
            if (response.data.result == "fail" || response.data == null) {
              window.localStorage.removeItem("PBAMEMBER");
              this.$alert(loginfailmsg);
            } else {
              let loginMember = {
                member_id: this.form.memberid,
                member_passwd: this.form.passwd,
                type: "admin",
              };
              //window.localStorage.removeItem("PBAMEMBER");
              window.localStorage.setItem(
                "PBAMEMBER",
                JSON.stringify(loginMember)
              );
              this.$router.push({ path: "/" });
              this.$router.go(0);
            }
          });
      } else {
        this.$alert(invalidmsg);
      }
    },
  },
};
</script>