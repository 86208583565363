import Vue from 'vue';
import Vuex from 'vuex';

import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import utils from './utils';
import auth from './auth';

Vue.use(Vuex);


export default new Vuex.Store({
  mutations,
  actions,
  getters,
  modules: {
    utils,
		auth,
	},
});