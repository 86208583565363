<template>
  <div class="row" style="margin-top: 80px">
    <div
      class="container mt-4"
      style="margin-top: 40px; padding-top: 5px; font-size: 12px"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="row"
        style="margin-top:5px!importance,margin-bottom: 5px;background-color:turquoise;"
      >
        <div class="col-lg-8 col-sm-8">
          <label style="margin-top: 10px">คำถาม</label>
          <div class="col-lg-12">
            <span v-if="editIndex !== index" rows="5">
              <textarea
                class="form-control form-control-sm"
                style="background-color: white"
                v-model="item.question"
                rows="3"
                width="100%"
                disabled
              ></textarea>
            </span>
            <span v-if="editIndex === index">
              <textarea
                class="form-control form-control-sm"
                style="background-color: white"
                v-model="item.question"
                width="100%"
                rows="5"
              ></textarea>
            </span>
          </div>
          <div class="row">
            <div class="col-6">
              <label
                style="margin-top: 5px; padding-left: 20px; text-align: center"
                >เวลาเริ่มต้น</label
              >
              <div class="col-lg-12">
                <span v-if="editIndex !== index">{{ item.start_time }}</span>
                <Datepicker
                  v-if="editIndex == index"
                  v-model="item.start_time"
                  format="YYYY-MM-DD H:i:s"
                  width="100%"
                />
              </div>
            </div>
            <div class="col-6">
              <label
                style="margin-top: 5px; padding-left: 20px; text-align: center"
                >เวลาสิ้นสุด</label
              >
              <div class="col-lg-12">
                <span v-if="editIndex !== index">{{ item.end_time }}</span>
                <Datepicker
                  v-if="editIndex == index"
                  v-model="item.end_time"
                  format="YYYY-MM-DD H:i:s"
                  width="100%"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label style="margin-top: 5px; padding-left: 20px; text-align: center"> จำนวนคำตอบที่ให้เลือก : </label>
              <span v-if="editIndex !== index">
                <label style="margin-top: 5px; padding-left: 20px; text-align: center"> {{ item.maxmultiple}} </label>
              </span>
              <select style="margin-top: 5px; padding-left: 20px; text-align: center" v-if="editIndex === index" v-model="item.maxmultiple">
                <option v-for="n in 50" :key="n" :value="n">{{ n }}</option>
              </select>
      
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-4" style="margin-top: 5px">
          <span v-if="editIndex !== index" style="margin-top: 5px">
            <button
              @click="edit(item, index)"
              class="btn btn-sm btn-secondary mr-2"
            >
              Edit
            </button>
            <button
              @click="editChoice(item.id)"
              class="btn btn-sm btn-secondary mr-2"
            >
              Edit Choices
            </button>
            <button
              @click="viewGraphResult(item.id)"
              class="btn btn-sm btn-secondary mr-2"
            >
              Graph
            </button>
            <button
              @click="viewTableResult(item.id)"
              class="btn btn-sm btn-secondary mr-2"
            >
              Summery Entry
            </button>
             <!-- <button
              @click="viewTableCorrectResult(item.id)"
              class="btn btn-sm btn-secondary mr-2"
            >
              Correct Summary
            </button> -->
            <button
              @click="deleteQuestionDatabase(item.id)"
              class="btn btn-sm btn-secondary mr-2"
            >
              Remove
            </button>
          </span>
          <span v-else style="margin-top: 5px">
            <button class="btn btn-sm btn-secondary mr-2" @click="cancel(item)">
              Cancel
            </button>
            <button
              class="btn btn-sm btn-secondary mr-2"
              @click="
                saveQuestionDatabase(
                  item.id,
                  item.isMultiple,
                  item.maxmultiple,
                  item.start_time,
                  item.end_time,
                  item.question
                )
              "
            >
              Save
            </button>
          </span>
        </div>
      </div>
      <div class="col-3 offset-9 text-right my-3">
        <button
          v-show="!editIndex"
          @click="cancelEdit"
          class="btn btn-sm btn-secondary"
          style="margin-top: 30px"
        >
          Cancel
        </button>
      </div>
      <!-- <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th width="1">#</th>
          <th width="40%">คำถาม</th>
          <th width="10%">เวลาเริ่มต้น</th>
          <th width="10%">เวลาสิ้นสุด</th>
          <th width="100%">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ index + 1 }}</td>
          <td>
            <span v-if="editIndex !== index">{{ item.question }}</span>
            <span v-if="editIndex === index">
              <input
                class="form-control form-control-sm"
                v-model="item.question"
              />
            </span>
          </td>
          <td>
            <span v-if="editIndex !== index">{{ item.start_time }}</span>
            <Datepicker
              v-if="editIndex == index"
              v-model="item.start_time"
              format="YYYY-MM-DD H:i:s"
              width="80%"
            />
          </td>
          <td>
            <span v-if="editIndex !== index">{{ item.end_time }}</span>
            <Datepicker
              v-if="editIndex == index"
              v-model="item.end_time"
              format="YYYY-MM-DD H:i:s"
              width="80%"
            />
          </td>

          <td>
            <span v-if="editIndex !== index">
              <button
                @click="edit(item, index)"
                class="btn btn-sm btn-outline-secondary mr-2"
              >
                Edit
              </button>
              <button
                @click="editChoice(item.id)"
                class="btn btn-sm btn-outline-secondary mr-2"
              >
                Edit Choices
              </button>
              <button
                @click="deleteQuestionDatabase(item.id)"
                class="btn btn-sm btn-outline-secondary mr-2"
              >
                Remove
              </button>
            </span>
            <span v-else>
              <button
                class="btn btn-sm btn-outline-secondary mr-2"
                @click="cancel(item)"
              >
                Cancel
              </button>
              <button
                class="btn btn-sm btn-outline-secondary mr-2"
                @click="
                  saveQuestionDatabase(
                    item.id,
                    item.start_time,
                    item.end_time,
                    item.question
                  )
                "
              >
                Save
              </button>
            </span>
          </td>
        </tr>
      </tbody>
    </table> -->
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datetimepicker";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "AdminQuestionVoting",
  components: {
    Datepicker,
  },
  filters: {
    money: (value) =>
      new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(value),
  },

  data() {
    return {
      question_id: this.$route.params.id,
      editIndex: null,
      originalData: null,
      items: [],
    };
  },
  created() {
    this.getQuestionDatabase();
    if (this.user.type != "admin") this.$router.push("/");
  },
  methods: {
    mysqlDate() {
      let date = new Date().toISOString().slice(0, 19).replace("T", " ");
      return date;
    },
    viewTableResult(id) {
      this.$router.push("/admin/question/table/" + id);
    },
    viewTableCorrectResult(id) {
      this.$router.push("/admin/question/tablecorrect/" + id);
    },

    viewGraphResult(id) {
      this.$router.push("/admin/question/graph/" + id);
    },
    
    editChoice(id) {
      this.$router.push("/admin/question/" + id);
    },
    add() {
      this.originalData = null;
      this.items.push({
        ismultiple: false,
        maxmultiple: 1,
        code: "",
        question: "",
        start_time: this.mysqlDate(),
        end_time: this.mysqlDate(),
        create_date: this.mysqlDate(),
      });
      this.editIndex = this.items.length - 1;
    },

    edit(item, index) {
      this.originalData = Object.assign({}, item);
      this.editIndex = index;
    },
    cancelEdit() {
      this.$router.push("/admin/question");
    },
    cancel(item) {
      this.editIndex = null;

      if (!this.originalData) {
        this.items.splice(this.items.indexOf(item), 1);
        return;
      }

      Object.assign(item, this.originalData);
      this.originalData = null;
    },

    remove(item, index) {
      this.items.splice(index, 1);
    },

    save(item) {
      this.originalData = null;
      this.editIndex = item.code;
    },
    deleteQuestionDatabase(question_id) {
      this.$confirm("Are you sure to delete question?").then(() => {
        //do something...
        let request = {
          request: "delete_question",
          question_id: question_id,
          token: "JINNSOLUTION_PBA2.0",
        };
        axios
          .post("https://pba-thai.com/api/1.0/index.php", request, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            //console.log(response.data);
            if (response.data.result == "success") this.getQuestionDatabase();
            this.$router.push("/admin/question");
            this.$router.go(0);
            this.editIndex = null;
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    saveQuestionDatabase(
      id,
      ismultiple,
      maxmultiple,
      start_time,
      end_time,
      question
    ) {
      this.$confirm("Are you sure to create question?").then(() => {
        //do something...
        let request = {
          request: "create_question",
          ismultiple: ismultiple ? 1 : 0,
          maxmultiple: maxmultiple,
          id: id,
          start_time: start_time,
          end_time: end_time,
          question: question,
          token: "JINNSOLUTION_PBA2.0",
        };

        //console.log(request);
        axios
          .post("https://pba-thai.com/api/1.0/index.php", request, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data.result == "success") this.getQuestionDatabase();
            this.editIndex = null;
            //console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    getQuestionDatabase() {
      //do something...
      let request = {
        request: "request_question_id",
        question_id: this.question_id,
        token: "JINNSOLUTION_PBA2.0",
      };
     // console.log(request);
      axios
        .post("https://pba-thai.com/api/1.0/index.php", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
        //  console.log(response.data);
          if (response.data.result == "success") {
            this.items = response.data.data;
            this.editIndex = null;
          }
          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    subtotal(item) {
      return (
        item.qty * item.price - (item.qty * item.price * item.discount) / 100
      );
    },
  },

  computed: {
    ...mapGetters(["user"]),
    allSubTotal() {
      return this.items
        .map((item) => this.subtotal(item))
        .reduce((a, b) => a + b, 0);
    },

    total() {
      return this.tax
        ? this.allSubTotal + this.allSubTotal * (this.tax / 100)
        : this.allSubTotal;
    },
  },
};
</script>

<style>
.row {
  margin-top: 5px;
}
button {
  width: 80%;
  margin: 5px;
}
.span-capture img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}

table {
  background-color: turquoise;
}
input[type="number"] {
  text-align: right;
}
</style>