<template>
  <div class="App">
    <div class="vertical-center">
      <div class="inner-block">
        <div class="vue-tempalte">
          <form>
            <h3>ตั้ง รหัสผ่าน</h3>

            <div class="form-group">
              <label>รหัสผ่าน</label>
              <input
                @keypress="validateSamePassword"
                v-model="form.passwd1"
                :type="type"
                class="form-control form-control-lg"
              />
            </div>

            <div class="form-group">
              <label>ยืนยันรหัสผ่าน{{ validateSamePassword() }} </label>
              <input
                @keypress="validateSamePassword"
                v-model="form.passwd2"
                :type="type"
                class="form-control form-control-lg"
              />
            </div>

            <div class="row">
              <button
                @click="updateOnlyPasswd"
                type="button"
                class="btn btn-dark btn-lg btn-block"
              >
                ยืนยันเปลี่ยนรหัสผ่าน
              </button>
            </div>
            <!-- <div class="row">
              <button
                @click="updatePasswdToMobile"
                type="button"
                class="btn btn-dark btn-lg btn-block"
              >
                รีเซ็ตรหัสเป็นเบอร์โทร
              </button>
            </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      type: "text",
      belowmessage: "",
      form: {
        email: "",
        passwd1: "",
        passwd2: "",
        member_id: "",
        token: "JINNSOLUTION_PBA2.0",
      },
      isSamePassword: false,
      member: null,
    };
  },
  created() {
    this.form.member_id = this.user.member_id;
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
    validateSamePassword() {
      if (this.passwd1 != this.passwd2) {
        this.isSamePassword = false;
        return "(พาสเวอด์ยังไม่เหมือนกัน)";
      } else {
        this.isSamePassword = true;
        return "";
      }
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    updateOnlyPasswd() {
      let invalidpassword =
        " โปรดใส่ password ให้ตรงกัน ";
      let updatepasswdcomplete = "เปลี่ยนรหัสผ่าน สำเร็จ";

      if (this.form.passwd1 == this.form.passwd2 && this.form.passwd2 != "") {
        let request = {
          request_function: "passwd_update",
          request_member_id: this.form.member_id,
          request_passwd: this.form.passwd1,
          token: this.form.token,
        };
        axios
          .post("https://photofairbangkok.com/pba-member/api/3.0/", request, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            //console.log(response);
            if (response.data.result == "fail" || response.data == null) {
              window.localStorage.removeItem("PBAMEMBER");
            } else {
              let loginMember = {
                member_id: this.form.memberid,
                member_passwd: this.form.passwd1,
                type: "member",
              };
              window.localStorage.setItem(
                "PBAMEMBER",
                JSON.stringify(loginMember)
              );
              this.$alert(updatepasswdcomplete);
              this.$router.push({ path: "/" });
            }
          });
      } else {
        this.$alert(invalidpassword);
      }
    },
    updatePasswdToMobile() {
      let updatepasswdcomplete = "เปลี่ยนรหัสผ่าน เป็นเบอร์ Mobile สำเร็จ";

      let request = {
        request_function: "passwdmobile_update",
        request_member_id: this.form.memberid,
        token: this.form.token,
      };
      axios
        .post("https://photofairbangkok.com/pba-member/api/3.0/", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.result == "fail" || response.data == null) {
            window.localStorage.removeItem("PBAMEMBER");
          } else {
            let loginMember = {
              member_id: this.form.memberid,
              member_passwd: this.form.passwd1,
              type: "member",
            };
            window.localStorage.setItem(
              "PBAMEMBER",
              JSON.stringify(loginMember)
            );
            this.$alert(updatepasswdcomplete);
            this.$router.push({ path: "/" });
          }
        });
    },
  },
};
</script>
<style scoped>
.otp input[type="text"] {
  background-color: #ca8181;
}
</style>