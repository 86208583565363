<template>
  <div class="vertical-center">
    <div>
      <label v-if="errorStr"> Error: </label>
      <p style="text-align: center">{{ msg }} {{ errorStr }}</p>
    </div>
    <div class="inner-block" style="margin-bottom: 10px">
      <h4>ไม่มีการให้เลือกโหวตในช่วงเวลานี้ กำลังทดสอบระบบ</h4>

      <div v-for="(vote, index) in votes" :key="index" class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          @click="voteAnswer(vote.question)"
          :checked="vote.question == voteschoice"
        />
        <label class="form-check-label" for="flexRadioDefault1">
          {{ vote.question }}
        </label>
      </div>
      <p class="forgot-password text-right">**{{ voteschoice }}</p>
    </div>
    <div class="inner-block" style="margin-top: 10px">
      <div class="row">
        <div class="span-capture">
          <img class="span-capture" :src="img" />
        </div>
        <div class="span-capture">
          <WebCam
            ref="webcam"
            :deviceId="deviceId"
            width="100%"
            height="100%"
            @cameras="onCameras"
            @camera-change="onCameraChange"
            :isFrontCam="frontCam"
            :googleKey="googleKey"
          >
          </WebCam>
        </div>
      </div>
      <div class="row">
        <button
          @click="onCapture"
          type="button"
          class="btn btn-dark btn-lg btn-block"
        >
          เลือก</button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { WebCam } from "vue-cam-vision";

export default {
  name: "Voting",
  props: {
    msg: String,
  },
  data() {
    return {
      votes: [
        { question: "เลือก a", choice: ["a", "b"] },
        { question: "เลือก b", choice: null },
      ],
      voteschoice: null,
      foldericon: "/imgs/foldericon.jpeg",
      captures: [],
      imgReport: [],
      frontCam: false,
      webcam: null,
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      location: null,
      gettingLocation: false,
      errorStr: null,
      googleKey: "AIzaSyB7LXdvsXpgxdgMgW21sgOm7xSONC0ISwE",
    };
  },
  components: {
    WebCam,
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      //const [first, ..tail] = this.devices;
      const [first] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },
  created() {
    //do we support geolocation
    if (!("geolocation" in navigator)) {
      this.errorStr = "Geolocation is not available.";
      this.$alert(this.errorStr);
      return;
    }

    this.gettingLocation = true;
    // get position
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.gettingLocation = false;
        this.location = pos;
      },
      (err) => {
        this.gettingLocation = false;
        this.errorStr = err.message;
      }
    );
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
    canGeolocate() {
      return !!navigator.geolocation;
    },
  },
  methods: {
    voteAnswer(answer) {
      this.voteschoice = answer;
    },
    async onCapture() {
      this.img = await this.$refs.webcam.capture();
    },
    onStarted(stream) {
      console.log("On Started Event", stream);
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
    },
    async onStop() {
      await this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      console.log("On Camera Change Event", deviceId);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.span-capture {
  width: 50% !important;
  float: left !important;
}
.span-capture img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
